// This file represents the internal mapping of instruments after parsing.
import * as Midi from '../headers/midi';
import { DefaultInstrumentsArray } from './default_instruments';
import { DefaultTemperamentArray } from './default_temperaments';
import * as redux from '../redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionNames } from '../redux';
import { NormalNoteToDatunerNote, StroboProCpp } from '../wrapper/js_wrapper';
import { getItem, setItem, TingsToStore } from '../async_storage';

import { dbg } from '../debug/debug';

export const NoteSymbol = {
  C: Midi.SHARP_NOTE_NAMES[0],
  Cs: Midi.SHARP_NOTE_NAMES[1],
  D: Midi.SHARP_NOTE_NAMES[2],
  Ds: Midi.SHARP_NOTE_NAMES[3],
  E: Midi.SHARP_NOTE_NAMES[4],
  F: Midi.SHARP_NOTE_NAMES[5],
  Fs: Midi.SHARP_NOTE_NAMES[6],
  G: Midi.SHARP_NOTE_NAMES[7],
  Gs: Midi.SHARP_NOTE_NAMES[8],
  A: Midi.SHARP_NOTE_NAMES[9],
  As: Midi.SHARP_NOTE_NAMES[10],
  B: Midi.SHARP_NOTE_NAMES[11],
};

export const NoteSymbolFlat = {
  C: Midi.FLAT_NOTE_NAMES[0],
  Cs: Midi.FLAT_NOTE_NAMES[1],
  D: Midi.FLAT_NOTE_NAMES[2],
  Ds: Midi.FLAT_NOTE_NAMES[3],
  E: Midi.FLAT_NOTE_NAMES[4],
  F: Midi.FLAT_NOTE_NAMES[5],
  Fs: Midi.FLAT_NOTE_NAMES[6],
  G: Midi.FLAT_NOTE_NAMES[7],
  Gs: Midi.FLAT_NOTE_NAMES[8],
  A: Midi.FLAT_NOTE_NAMES[9],
  As: Midi.FLAT_NOTE_NAMES[10],
  B: Midi.FLAT_NOTE_NAMES[11],
};

export interface ElementBase {
  name: string;
  parent?: ElementBase;
}
export interface NoteElement extends ElementBase {
  symbol?: string;
  superscript?: string;
  subscript?: string;
  frequency?: string;
  cents?: string;
}

type NoteToFreqRel440Info = {
  note: number;
  octave: number;
  freqRel440: number;
};

export function NoteToFreqRel440(
  value: NoteElement,
  _currentTemperaments?: Array<number>,
): NoteToFreqRel440Info {
  let rval: NoteToFreqRel440Info = {
    note: 0,
    octave: 0,
    freqRel440: 1.0,
  };
  const currentTemperaments =
    _currentTemperaments && _currentTemperaments.length === 12
      ? _currentTemperaments
      : new Array(12).fill(0);
  if (value.frequency && value.frequency.length > 0) {
    // If a frequency is specified, do NOT apply temperament
    const freq = parseFloat(value.frequency);
    const note = Midi.GetNearestNoteAndError(freq);
    rval = { note: note.note, octave: note.octave, freqRel440: freq / 440.0 };
  } else if (value.name && value.name.length > 0) {
    // Else apply temperament based on the note symbol
    const c = Midi.ParseNote(value.name);
    const tweak = currentTemperaments[c.note % 12];
    const freq = Midi.NoteAndOctaveGetFreqFromNoteAndError(c, tweak);
    rval = { note: c.note, octave: c.octave, freqRel440: freq / 440.0 };
  }
  return rval;
}

export interface TuningElement extends ElementBase {
  noteElement: Array<NoteElement>;
  reference?: Object;
  transpose?: Object;
}

export interface SubInstrumentElement extends ElementBase {
  tuning: Array<TuningElement>;
}

export interface InstrumentElement extends ElementBase {
  subInstrument: Array<SubInstrumentElement>;
}

export interface InstrumentGeneric extends ElementBase {
  subInstrument?: Array<InstrumentGeneric>;
  tuning?: Array<InstrumentGeneric>;
  noteElement?: Array<InstrumentGeneric>;
}

let __inst: NoteMappings | null = null;
export class NoteMappings {
  // SIngleton getter.
  static inst(): NoteMappings {
    if (null === __inst) {
      __inst = new NoteMappings();
    }
    return __inst;
  }

  // Helper function fetches the store and then does something for all instruments.
  forAllTunings(cb: (instrument: string, subinstrument: string, tuning: string) => void) {
    const store = redux.Store.inst().store;
    const state = store.getState();
    state.system.allInstrumentsMap.forEach((instrument: InstrumentElement) => {
      instrument.subInstrument.forEach((subinstrument: SubInstrumentElement) => {
        subinstrument.tuning.forEach((tuning: TuningElement) => {
          cb(instrument.name, subinstrument.name, tuning.name);
        });
      });
    });
  }

  getInstruments(): Array<InstrumentElement> {
    let rval: Array<InstrumentElement> = [];
    const store = redux.Store.inst().store;
    const state = store.getState();

    state.system.allInstrumentsMap.forEach((instrument: InstrumentElement) => {
      rval.push(instrument);
    });
    return rval;
  }

  // Simply calls a function.
  hi() {}

  load() {
    // Load from xml.
    setTimeout(() => {
      const store = redux.Store.inst().store;
      DefaultTemperamentArray.forEach((inst: TuningElement) => {
        dbg.log('Adding temperament ' + inst.name);

        store.dispatch(redux.Dispatch.addTemperament(inst));
      });

      DefaultInstrumentsArray.forEach((inst: InstrumentElement) => {
        dbg.log(
          'Adding instrument ' +
            inst.name +
            ' with ' +
            inst.subInstrument.length +
            ' different types.',
        );

        store.dispatch(redux.Dispatch.addInstrument(inst));
      });
      store.dispatch(redux.Dispatch.info(redux.InfoNames.InstrumentsUpdateCompleted));
    }, 1000);
  }
}

// Get the child type of the parent.
export function getChildren(parent: InstrumentGeneric) {
  let rval: null | Array<InstrumentGeneric> = null;
  if (parent.subInstrument) {
    rval = parent.subInstrument;
  } else if (parent.tuning) {
    rval = parent.tuning;
  } else if (parent.noteElement) {
    rval = parent.noteElement;
  }
  return rval;
}

// Goes downwards from parent to the child and lets the children hold the parents hand.
function recursiveAddParents(parent: InstrumentGeneric) {
  const children = getChildren(parent);
  if (children) {
    children.forEach((child: InstrumentGeneric) => {
      child.parent = parent;

      recursiveAddParents(child);
    });
  }
}

function doSomethingAfterAppInit(args: any[]): string {
  NoteMappings.inst().load();
  return 'didSomethingAfterAppInit';
}

function* onAppInit(actionAny: any, ..._otherArgs: any[]) {
  yield call(doSomethingAfterAppInit, actionAny);
}

function* onAppInitEffect() {
  yield takeEvery(redux.ActionNames.InitAction, onAppInit);
}

//-----------------------------------------------------------------------------
// START Filters to run BEFORE saving state.
//-----------------------------------------------------------------------------
export function NoteMappingMiddleWare(_store: any) {
  return (next: any) => {
    return (actionAny: any /*redux.ReduxActions*/) => {
      const action: redux.ReduxActions = actionAny;
      switch (action.type) {
        case 'AddInstrumentAction': {
          // Intercept the instrument being added and make it searchable from child to parent.
          const addInstrument: redux.AddInstrumentAction = actionAny;
          const instrumentAny: any = addInstrument.instrument;
          recursiveAddParents(instrumentAny);
          break;
        }
        default:
          break;
      }

      // continue processing this action
      return next(action);
    };
  };
}
//-----------------------------------------------------------------------------
// END Filters to run BEFORE saving state.
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// START Filters to run AFTER saving state.
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// InstrumentUpdate -----
function doSomethingAfterInstrumentsUpdated(args: any[]): string {
  return 'didSomething';
}

function* onInstrumentsUpdated(actionAny: any) {
  //const _action: redux.ReduxActions = actionAny;
  //const _something = yield call(doSomethingAfterInstrumentsUpdated, actionAny);
  yield put({ type: 'InstrumentsUpdated' });
}

function* onInstrumentsUpdatedEffect() {
  yield takeEvery(ActionNames.AddInstrumentAction, onInstrumentsUpdated);
}
// END InstrumentUpdate -----
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// SetInstrument -----
function* onSetInstrumentUpdate(_actionAny: any) {
  const action: redux.SetCurrentInstrumentAction = _actionAny;

  // TODO: Fetch and update the mapping in the native layer.
  dbg.log('Here is where we update the frequency mapping.');

  const store = redux.Store.inst().store;
  const state = store.getState();

  const instrument = state.system.allInstrumentsMap.get(action.instrumentName);
  if (instrument) {
    const subInstruments = getChildren(instrument);
    if (subInstruments) {
      const subInstrument = subInstruments.find((value: InstrumentGeneric) => {
        return value.name === action.subInstrumentName;
      }, action.subInstrumentName);
      if (subInstrument) {
        const tunings = getChildren(subInstrument);
        if (tunings) {
          const tuning = tunings.find((value: InstrumentGeneric) => {
            return value.name === action.tuningName;
          }, action.tuningName);
          if (tuning) {
            const t: TuningElement = tuning as TuningElement;
            store.dispatch(redux.Dispatch.setTuning(t));
          }
        }
      }
    }
  } else {
    // No instrument, go to chromatic.
    store.dispatch(redux.Dispatch.setTuning(null));
  }

  yield put({ type: 'FakeUpdatedDispatchAfterThisAction', action });

  yield call(doSomethingAfterInstrumentsUpdated, _actionAny);
}

function* onSetInstrumentEffect() {
  yield takeEvery(ActionNames.SetCurrentInstrumentAction, onSetInstrumentUpdate);
}

// END SetInstrument -----
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// TuningChosen -----
function doSomethingAfterTuningChosen(a: redux.SetTuningAction): string {
  let savedToNvm = false;
  dbg.log(a.tuning);
  if (a.tuning && a.tuning.name && a.tuning.name.length > 0) {
    const parent = a.tuning.parent;
    const grandparent = parent?.parent;
    if (parent && grandparent) {
      // From tuning, navigate upwards to find parent and grandparent.
      setItem(TingsToStore.Instrument, grandparent.name).catch();
      setItem(TingsToStore.SubInstrument, parent.name).catch();
      setItem(TingsToStore.Tuning, a.tuning.name).catch();
      savedToNvm = true;
    }
  }
  if (!savedToNvm) {
    setItem(TingsToStore.Instrument, '').catch();
    setItem(TingsToStore.SubInstrument, '').catch();
    setItem(TingsToStore.Tuning, '').catch();
  }

  return 'Saved items after tunings chosen';
}

function getCurrentTemperament(alwaysReturnArray: boolean = true): undefined | Array<number> {
  let rval: undefined | Array<number> = undefined;
  const store = redux.Store.inst().store;
  const state = store.getState();
  const currTemperamentName = state.system.currentTemperamentName;
  const currentTemperament = state.system.allTemperamentsMap.get(currTemperamentName);
  if (currentTemperament) {
    const centsTweak: Array<number> = currentTemperament.noteElement.map((value: NoteElement) => {
      return value.cents ? parseFloat(value.cents) : 0;
    });
    rval = [...centsTweak];
  }
  if (!rval && alwaysReturnArray) {
    rval = new Array(12).fill(0);
  }
  return rval;
}

function* onTuningChosen(actionAny: any, ...otherArgs: any[]) {
  const _action: redux.ReduxActions = actionAny;
  const action: redux.SetTuningAction = _action as redux.SetTuningAction;
  const tuning = action.tuning;
  let freqs: Array<number> = [];
  let octaves: Array<number> = [];
  let notes: Array<number> = [];
  let tags: Array<number> = [];
  let doCommit = false;
  const currentTemperaments = getCurrentTemperament();

  if (tuning) {
    const _children = getChildren(tuning);
    if (_children) {
      const parent = tuning.parent;
      const grandparent = parent?.parent;
      if (parent && grandparent) {
        doCommit = true;
        const children: Array<NoteElement> = _children as Array<NoteElement>;
        const relFreqs: Array<NoteToFreqRel440Info> = children.map((value: NoteElement) => {
          const info = NoteToFreqRel440(value, currentTemperaments);
          return info;
        });
        relFreqs.forEach((info: NoteToFreqRel440Info) => {
          const relFreq = info.freqRel440;
          const da = NormalNoteToDatunerNote(info.note, info.octave);
          freqs.push(relFreq * 440.0);
          octaves.push(da.o);
          notes.push(da.n);
          tags.push(Math.floor(relFreq * 440 * 1000));
        });
        doCommit = true;
      }
    }
  } else {
    // We need to make a chromatic mapping to apply the tunings to.
    if (currentTemperaments) {
      const centsTweak: Array<number> = currentTemperaments;
      const { minFreq, maxFreq } = redux.store.getState().system;
      const first: Midi.GetNoteErrorResult = Midi.GetNearestNoteAndError(minFreq);
      const last: Midi.GetNoteErrorResult = Midi.GetNearestNoteAndError(maxFreq);
      const octave0 = first.octave;

      for (let o = octave0; o <= last.octave; o++) {
        for (let n = 0; n < 12; n++) {
          const cent = centsTweak[n];
          const c: Midi.NoteAndOctave = {
            note: n,
            octave: o,
          };
          const freq = Midi.NoteAndOctaveGetFreqFromNoteAndError(c, cent);
          const da = NormalNoteToDatunerNote(n, o);
          freqs.push(freq);
          octaves.push(da.o);
          notes.push(da.n);
          tags.push(Math.floor(freq * 1000));
        }
      }
    }

    doCommit = true;
  }
  if (doCommit) {
    const freqsAry: Float32Array = Float32Array.from(freqs);
    const notesAry: Int32Array = Int32Array.from(notes);
    const octavesAry: Int32Array = Int32Array.from(octaves);
    const tagsAry: Int32Array = Int32Array.from(tags);
    StroboProCpp.inst().WrapperUpdateNotes(freqsAry, notesAry, octavesAry, tagsAry);
  }

  yield call(doSomethingAfterTuningChosen, actionAny);
  yield put({ type: 'TuningChosen' });
}

function* onTuningChosenEffect() {
  yield takeEvery(ActionNames.SetTuningAction, onTuningChosen);
}
// END TuningChosen -----
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// TemperamentUpdate -----
function doSomethingAfterTemperamentsUpdated(a: redux.SetCurrentTemperamentAction): string {
  dbg.log('doSomethingAfterTemperamentsUpdated', a);
  if (a.temperamentName && a.temperamentName.length > 0) {
    // From tuning, navigate upwards to find parent and grandparent.
    setItem(TingsToStore.Temperament, a.temperamentName).catch();
  } else {
    setItem(TingsToStore.Temperament, '').catch();
    dbg.log('Saving no temperament.');
  }

  return 'Saved items after temperament chosen';
}

//-----------------------------------------------------------------------------
// SetTemperament -----
function* onSetTemperamentUpdate(_actionAny: any) {
  const action: redux.SetCurrentTemperamentAction = _actionAny;
  // TODO: Fetch and update the mapping in the native layer.
  dbg.log('temperaments::Here is where we update the temperaments');

  const store = redux.Store.inst().store;
  const state = store.getState();

  const tuning = state.system.currentTuning ? state.system.currentTuning : null;
  store.dispatch(redux.Dispatch.setTuning(tuning));
  yield put({ type: 'FakeUpdatedDispatchAfterSetTemperaments', action });
  yield call(doSomethingAfterTemperamentsUpdated, _actionAny);
}

function* onSetTemperamentEffect() {
  yield takeEvery(ActionNames.SetCurrentTemperamentAction, onSetTemperamentUpdate);
}

// END SetTemperament -----
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// Initialization -----
function doSomethingAfterInitialization(a: redux.InitAction): string {
  getItem(TingsToStore.Instrument).then((instrument: null | string) => {
    getItem(TingsToStore.SubInstrument).then((subInstrument: null | string) => {
      getItem(TingsToStore.Tuning).then((tuning: null | string) => {
        getItem(TingsToStore.Temperament).then((_temperament: null | string) => {
          const temperament = _temperament ? _temperament : '';
          redux.Store.inst().store.dispatch(
            redux.Dispatch.setCurrentTemperamentByName(temperament),
          );
          if (instrument && subInstrument && tuning) {
            if (instrument.length > 0 && subInstrument.length > 0 && tuning.length > 0) {
              dbg.log('Setting current instrument to ', instrument, subInstrument, tuning);
              redux.Store.inst().store.dispatch(
                redux.Dispatch.setCurrentInstrumentByName(instrument, subInstrument, tuning),
              );
            }
          }
        });
      });
    });
  });

  return 'Saved items after tunings chosen';
}

function* onInitialization(actionAny: any, ..._otherArgs: any[]) {
  yield call(doSomethingAfterInitialization, actionAny);
}

function* onInitializationEffect() {
  yield takeEvery(redux.InfoNames.InstrumentsUpdateCompleted, onInitialization);
}
// END Initialization -----
//-----------------------------------------------------------------------------

//-----------------------------------------------------------------------------
// END Filters to run BEFORE saving state.
//-----------------------------------------------------------------------------

// Side effects
export const NoteMappingSideEffects = [
  onAppInitEffect,
  onInstrumentsUpdatedEffect,
  onSetInstrumentEffect,
  onTuningChosenEffect,
  onSetTemperamentEffect,
  onInitializationEffect,
];
