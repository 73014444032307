import { StroboProCpp } from './wrapper/js_wrapper';
import { AudioChunker } from './audio_chunker';
import { dbg } from './debug/debug';

let _inst: undefined | TunerAudio = undefined;
export class TunerAudio {
  strobo: StroboProCpp;
  isAudioSetUp = false;
  decimation = 4;
  bufferSize = 4096;
  datuner_sample_rate = 48000 / this.decimation;
  audioChunker = new AudioChunker();

  // global to avoid GC on firefox and safari
  audioContext?: AudioContext;
  myPCMProcessingNode?: ScriptProcessorNode = undefined;
  microphone: any = undefined;
  antiAliasLpf1?: BiquadFilterNode = undefined;
  antiAliasLpf2?: BiquadFilterNode = undefined;
  dcRemoveHpf?: BiquadFilterNode = undefined;

  static inst(): TunerAudio {
    if (!_inst) {
      _inst = new TunerAudio();
    }
    const a: any = _inst;
    const tuner: TunerAudio = a;
    return tuner;
  }

  pendingBuffers: Array<Float32Array> = [];

  doProcess() {
    if (this.pendingBuffers.length > 0) {
      const buffers = [...this.pendingBuffers];
      this.pendingBuffers = [];
      buffers.forEach((buffer: Float32Array) => {
        this.audioChunker.chunkData(buffer);
      });
    }
  }

  audioProcessorFunction = (audioProcessingEvent: AudioProcessingEvent) => {
    try {
      // Start reading the next buffer from the index left over from the last one.
      let inputBuffer = audioProcessingEvent.inputBuffer.getChannelData(0);
      if (this.pendingBuffers.length < 3) {
        this.pendingBuffers.push(inputBuffer);
      }
      if (this.pendingBuffers.length <= 2) {
        setTimeout(() => {
          this.doProcess();
        }, 0);
      }
    } catch (exception) {
      alert('Exception in wrapper_add_samples():' + exception);
    }
  };

  constructor() {
    this.strobo = StroboProCpp.inst();
    this.datuner_sample_rate = 48000 / this.decimation;
    this.pendingBuffers = [];

    if (this.isAudioSetUp) {
      dbg.log('Audio already set up');
      return;
    }

    // Aim for 30Hz refreshing /polling
    let decBufferSize = this.datuner_sample_rate / 30;

    // Ensure is a multiple of 32.
    decBufferSize = Math.floor(decBufferSize / 32);
    decBufferSize = Math.floor(decBufferSize * 32);

    // Reinitialize audio chunker.
    this.audioChunker.reInit(decBufferSize, this.decimation, (pArr: Float32Array) => {
      this.strobo.WrapperAddSamples(pArr);
    });

    try {
      const wany: any = window;
      const AudioContext = window.AudioContext || wany.webkitAudioContext;
      this.audioContext = new AudioContext();
      let minSampleRate = 11000;
      let minBufferSize = 512;
      let sampleRatio = Math.floor(this.audioContext.sampleRate / minSampleRate);
      let sampleRatioLog2 = Math.round(Math.log(sampleRatio) / Math.log(2));
      let nearestLog2Ratio = Math.pow(2, sampleRatioLog2);
      this.bufferSize = minBufferSize * nearestLog2Ratio;
      this.decimation = Math.round(sampleRatio);

      this.datuner_sample_rate = this.audioContext.sampleRate / this.decimation;
      this.strobo.WrapperChangeFs(this.datuner_sample_rate);
      this.strobo.WrapperChangeAutoSensDb(16);
    } catch (e) {
      alert('Web Audio API is not supported in this browser:' + e);
    }

    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      alert(
        'Your device does not support WebAudio. Please try a recent version of Chrome, Firefox, or Safari',
      );
    } else {
      this.isAudioSetUp = true;

      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(
        // successful
        (mediaStream: MediaStream) => {
          //dbg.log('got mediastream', mediaStream);
          // Update sample rate to our decimated sample rate
          TunerAudio.inst().strobo.WrapperChangeFs(this.datuner_sample_rate);
          const audioContext = this.audioContext;
          if (audioContext) {
            // Get the microphone and connect it to the high pass filter.
            let microphone = audioContext.createMediaStreamSource(mediaStream);
            this.dcRemoveHpf = audioContext.createBiquadFilter();
            this.dcRemoveHpf.type = 'highpass';
            this.dcRemoveHpf.frequency.value = 55;

            this.dcRemoveHpf.gain.value = 1;
            microphone.connect(this.dcRemoveHpf);
            this.myPCMProcessingNode = audioContext.createScriptProcessor(this.bufferSize, 1, 1);

            // Add an antialiasing filter if the sample rate is too high.
            if (this.myPCMProcessingNode) {
              if (this.decimation > 1) {
                // Create an antialiasing filter and decimate the input
                this.antiAliasLpf1 = audioContext.createBiquadFilter();
                this.antiAliasLpf1.type = 'lowpass';
                this.antiAliasLpf1.frequency.value = this.datuner_sample_rate * 0.44;
                this.antiAliasLpf1.gain.value = 1;
                this.antiAliasLpf2 = audioContext.createBiquadFilter();
                this.antiAliasLpf2.type = 'lowpass';
                this.antiAliasLpf2.frequency.value = this.datuner_sample_rate * 0.44;
                this.antiAliasLpf2.gain.value = 1;

                // Connect the microphone to the antialiasing filter.
                this.dcRemoveHpf.connect(this.antiAliasLpf1);
                this.antiAliasLpf1.connect(this.antiAliasLpf2);
                this.antiAliasLpf2.connect(this.myPCMProcessingNode);
              } else {
                this.dcRemoveHpf.connect(this.myPCMProcessingNode);
              }
              this.myPCMProcessingNode.connect(audioContext.destination);
              //this.myPCMProcessingNode.onaudioprocess = this.audioProcessorFunction;
              this.myPCMProcessingNode.addEventListener(
                'audioprocess',
                this.audioProcessorFunction,
              );
            }
          }
        },
        (error: MediaStreamError) => {
          alert('MediaStreamError:' + JSON.stringify(error, null, 2));
        },
      );
    }
  }

  hi() {}
}
