import { InstrumentElement } from './note_mapping';

export const DefaultInstrumentsArray: Array<InstrumentElement> = [
  {
    name: 'Guitar',
    subInstrument: [
      {
        name: '6-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'E2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Drop-D',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-A',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'C#3',
              },
              {
                name: 'A3',
              },
              {
                name: 'C#4',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-C',
            noteElement: [
              {
                name: 'C2',
              },
              {
                name: 'G2',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-D',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'A3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-D7',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'C4',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-Dsus',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'A3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-E',
            noteElement: [
              {
                name: 'E2',
              },
              {
                name: 'B2',
              },
              {
                name: 'E3',
              },
              {
                name: 'G#3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-Em',
            noteElement: [
              {
                name: 'E2',
              },
              {
                name: 'B2',
              },
              {
                name: 'E3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-Fm7',
            noteElement: [
              {
                name: 'F2',
              },
              {
                name: 'G#2',
              },
              {
                name: 'C3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'G#3',
              },
              {
                name: 'F#4',
              },
            ],
          },
          {
            name: 'Open-G',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-G6',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-Gm',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'A#3',
              },
              {
                name: 'D4',
              },
            ],
          },
        ],
      },
      {
        name: '12-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'E2',
                symbol: '12',
              },
              {
                name: 'E3',
                symbol: '11',
              },
              {
                name: 'A2',
                symbol: '10',
              },
              {
                name: 'A3',
                symbol: '9',
              },
              {
                name: 'D3',
                symbol: '8',
              },
              {
                name: 'D4',
                symbol: '7',
              },
              {
                name: 'G3',
                symbol: '6',
              },
              {
                name: 'G4',
                symbol: '5',
              },
              {
                name: 'B3',
                symbol: '4+3',
              },
              {
                name: 'E4',
                symbol: '2+1',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Bass Guitar',
    subInstrument: [
      {
        name: '6-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'B0',
                symbol: '6',
                superscript: 'B0',
                subscript: '30.86',
              },
              {
                name: 'E1',
                symbol: '5',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '4',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '3',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '2',
                superscript: 'G2',
                subscript: '98.00',
              },
              {
                name: 'C3',
                symbol: '1',
                superscript: 'C3',
                subscript: '130.81',
              },
            ],
          },
        ],
      },
      {
        name: '5-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'B0',
                symbol: '5',
                superscript: 'B0',
                subscript: '30.86',
              },
              {
                name: 'E1',
                symbol: '4',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '3',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '2',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '1',
                superscript: 'G2',
                subscript: '98.00',
              },
            ],
          },
        ],
      },
      {
        name: '4-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'E1',
                symbol: '4',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '3',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '2',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '1',
                superscript: 'G2',
                subscript: '98.00',
              },
            ],
          },
          {
            name: 'Drop D',
            noteElement: [
              {
                name: 'D1',
                symbol: '4',
                superscript: 'D1',
                subscript: '38.89',
              },
              {
                name: 'A1',
                symbol: '3',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '2',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '1',
                superscript: 'G2',
                subscript: '98.00',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Ukelele',
    subInstrument: [
      {
        name: '4-String',
        tuning: [
          {
            name: 'A4-D4-F#4-B4',
            noteElement: [
              {
                name: 'A4',
              },
              {
                name: 'D4',
              },
              {
                name: 'F#4',
              },
              {
                name: 'B4',
              },
            ],
          },
          {
            name: 'G4-C4-E4-A4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
              {
                name: 'A4',
              },
            ],
          },
          {
            name: 'G3-C4-E4-A4',
            noteElement: [
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
              {
                name: 'A4',
              },
            ],
          },
          {
            name: 'D4-G3-B3-E4',
            noteElement: [
              {
                name: 'D4',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Piano',
    subInstrument: [
      {
        name: '88-Key',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'A0',
                superscript: '1',
              },
              {
                name: 'A#0',
                superscript: '2',
              },
              {
                name: 'B0',
                superscript: '3',
              },
              {
                name: 'C1',
                superscript: '4',
              },
              {
                name: 'C#1',
                superscript: '5',
              },
              {
                name: 'D1',
                superscript: '6',
              },
              {
                name: 'D#1',
                superscript: '7',
              },
              {
                name: 'E1',
                superscript: '8',
              },
              {
                name: 'F1',
                superscript: '9',
              },
              {
                name: 'F#1',
                superscript: '10',
              },
              {
                name: 'G1',
                superscript: '11',
              },
              {
                name: 'G#1',
                superscript: '12',
              },
              {
                name: 'A1',
                superscript: '13',
              },
              {
                name: 'A#1',
                superscript: '14',
              },
              {
                name: 'B1',
                superscript: '15',
              },
              {
                name: 'C2',
                superscript: '16',
              },
              {
                name: 'C#2',
                superscript: '17',
              },
              {
                name: 'D2',
                superscript: '18',
              },
              {
                name: 'D#2',
                superscript: '19',
              },
              {
                name: 'E2',
                superscript: '20',
              },
              {
                name: 'F2',
                superscript: '21',
              },
              {
                name: 'F#2',
                superscript: '22',
              },
              {
                name: 'G2',
                superscript: '23',
              },
              {
                name: 'G#2',
                superscript: '24',
              },
              {
                name: 'A2',
                superscript: '25',
              },
              {
                name: 'A#2',
                superscript: '26',
              },
              {
                name: 'B2',
                superscript: '27',
              },
              {
                name: 'C3',
                superscript: '28',
              },
              {
                name: 'C#3',
                superscript: '29',
              },
              {
                name: 'D3',
                superscript: '30',
              },
              {
                name: 'D#3',
                superscript: '31',
              },
              {
                name: 'E3',
                superscript: '32',
              },
              {
                name: 'F3',
                superscript: '33',
              },
              {
                name: 'F#3',
                superscript: '34',
              },
              {
                name: 'G3',
                superscript: '35',
              },
              {
                name: 'G#3',
                superscript: '36',
              },
              {
                name: 'A3',
                superscript: '37',
              },
              {
                name: 'A#3',
                superscript: '38',
              },
              {
                name: 'B3',
                superscript: '39',
              },
              {
                name: 'C4',
                superscript: '40',
              },
              {
                name: 'C#4',
                superscript: '41',
              },
              {
                name: 'D4',
                superscript: '42',
              },
              {
                name: 'D#4',
                superscript: '43',
              },
              {
                name: 'E4',
                superscript: '44',
              },
              {
                name: 'F4',
                superscript: '45',
              },
              {
                name: 'F#4',
                superscript: '46',
              },
              {
                name: 'G4',
                superscript: '47',
              },
              {
                name: 'G#4',
                superscript: '48',
              },
              {
                name: 'A4',
                superscript: '49',
              },
              {
                name: 'A#4',
                superscript: '50',
              },
              {
                name: 'B4',
                superscript: '51',
              },
              {
                name: 'C5',
                superscript: '52',
              },
              {
                name: 'C#5',
                superscript: '53',
              },
              {
                name: 'D5',
                superscript: '54',
              },
              {
                name: 'D#5',
                superscript: '55',
              },
              {
                name: 'E5',
                superscript: '56',
              },
              {
                name: 'F5',
                superscript: '57',
              },
              {
                name: 'F#5',
                superscript: '58',
              },
              {
                name: 'G5',
                superscript: '59',
              },
              {
                name: 'G#5',
                superscript: '60',
              },
              {
                name: 'A5',
                superscript: '61',
              },
              {
                name: 'A#5',
                superscript: '62',
              },
              {
                name: 'B5',
                superscript: '63',
              },
              {
                name: 'C6',
                superscript: '64',
              },
              {
                name: 'C#6',
                superscript: '65',
              },
              {
                name: 'D6',
                superscript: '66',
              },
              {
                name: 'D#6',
                superscript: '67',
              },
              {
                name: 'E6',
                superscript: '68',
              },
              {
                name: 'F6',
                superscript: '69',
              },
              {
                name: 'F#6',
                superscript: '70',
              },
              {
                name: 'G6',
                superscript: '71',
              },
              {
                name: 'G#6',
                superscript: '72',
              },
              {
                name: 'A6',
                superscript: '73',
              },
              {
                name: 'A#6',
                superscript: '74',
              },
              {
                name: 'B6',
                superscript: '75',
              },
              {
                name: 'C7',
                superscript: '76',
              },
              {
                name: 'C#7',
                superscript: '77',
              },
              {
                name: 'D7',
                superscript: '78',
              },
              {
                name: 'D#7',
                superscript: '79',
              },
              {
                name: 'E7',
                superscript: '80',
              },
              {
                name: 'F7',
                superscript: '81',
              },
              {
                name: 'F#7',
                superscript: '82',
              },
              {
                name: 'G7',
                superscript: '83',
              },
              {
                name: 'G#7',
                superscript: '84',
              },
              {
                name: 'A7',
                superscript: '85',
              },
              {
                name: 'A#7',
                superscript: '86',
              },
              {
                name: 'B7',
                superscript: '87',
              },
              {
                name: 'C8',
                superscript: '88',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Violin',
    subInstrument: [
      {
        name: '4 String Violin',
        tuning: [
          {
            name: '4 String Standard',
            noteElement: [
              {
                name: 'G3',
                symbol: 'G',
                subscript: '3',
              },
              {
                name: 'D4',
                symbol: 'D',
                subscript: '4',
              },
              {
                name: 'A4',
                symbol: 'A',
                subscript: '4',
              },
              {
                name: 'E5',
                symbol: 'E',
                subscript: '5',
              },
            ],
          },
        ],
      },
      {
        name: '5 String Violin',
        tuning: [
          {
            name: '5 String Standard',
            noteElement: [
              {
                name: 'C3',
                symbol: 'C',
                subscript: '3',
              },
              {
                name: 'G3',
                symbol: 'G',
                subscript: '3',
              },
              {
                name: 'D4',
                symbol: 'D',
                subscript: '4',
              },
              {
                name: 'A4',
                symbol: 'A',
                subscript: '4',
              },
              {
                name: 'E5',
                symbol: 'E',
                subscript: '5',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Banjo',
    subInstrument: [
      {
        name: '4-String',
        tuning: [
          {
            name: 'Tenor C3 G3 D4 A4',
            noteElement: [
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'D4',
              },
              {
                name: 'A4',
              },
            ],
          },
          {
            name: 'Irish Tenor G2 D3 A3 E4',
            noteElement: [
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'A3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Plectrum C3 G3 B3 D4',
            noteElement: [
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
        ],
      },
      {
        name: '5-String',
        tuning: [
          {
            name: 'G G4-D3-G3-B3-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'C G4-C3-G3-B3-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'D A4-D3-F#3-A3-D4',
            noteElement: [
              {
                name: 'A4',
              },
              {
                name: 'D3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'A3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Double C G4-C3-G3-C4-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open C G4-C3-G3-C4-E4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open Cm G4-C3-G3-C4-Eb4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'F#4',
              },
            ],
          },
          {
            name: 'Open Gm G4-D3-G3-Bb3-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'Bb3',
              },
              {
                name: 'D4',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Mandolin',
    subInstrument: [
      {
        name: 'Mandolin',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'D4',
              },
              {
                name: 'A4',
              },
              {
                name: 'E5',
              },
            ],
          },
        ],
      },
    ],
  },
];
