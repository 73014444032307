import React from 'react';

import {
  PersistentDrawerLeftChild,
  PersistentDrawerLeftProps,
} from './persistent_drawer_left_child';
import './App.css';

export interface TunerMenuProps extends PersistentDrawerLeftProps {
  children?: JSX.Element;
}

export enum DrawerItems {
  CONTACT = 0,
}

export function PersistentDrawerLeft(props: TunerMenuProps) {
  return (
    <>
      <PersistentDrawerLeftChild
        {...props}
        onPressItem={(itemIdx: number) => {
          switch (itemIdx) {
            case 0:
              // About page...
              break;
            case 1:
              // About page...
              break;

            default:
              break;
          }
        }}
      >
        {props.children}
      </PersistentDrawerLeftChild>
    </>
  );
}
