import { SHARP_NOTE_NAMES } from '../headers/midi';
import * as tuner from './../tuner_ts';

let _inst: StroboProCpp | null = null;

export enum DatunerNotes {
  E_NOTE_A = 0,
  E_NOTE_Ash = 1,
  E_NOTE_B = 2,
  E_NOTE_C = 3,
  E_NOTE_Csh = 4,
  E_NOTE_D = 5,
  E_NOTE_Dsh = 6,
  E_NOTE_E = 7,
  E_NOTE_F = 8,
  E_NOTE_Fsh = 9,
  E_NOTE_G = 10,
  E_NOTE_Gs = 11,
}

export enum NormalNotes {
  E_NOTE_C = 0,
  E_NOTE_Csh,
  E_NOTE_D,
  E_NOTE_Dsh,
  E_NOTE_E,
  E_NOTE_F,
  E_NOTE_Fsh,
  E_NOTE_G,
  E_NOTE_Gs,
  E_NOTE_A,
  E_NOTE_Ash,
  E_NOTE_B,
}

export enum PollResults {
  gotResult = 0, // 0
  note, // 1
  freqHz, // 2
  centsError, // 3
  octave, // 4
  perfectFreq, // 5
  weighting, // 6
  dummy0, // 7
  dummy1, // 8
  dummy2, // 9
  gotSigEvt, // 10
  dBFSSignal, // 11
  dbFSThreshold, // 12
  dbFSNoiseFloor, // 13
  analysisActive, // 14
  strobesActive, // 15
  strobe0_amp, // 16
  strobe0_position, // 17
  strobe0_velocity, // 18
  strobe0_acceleration, // 19
  strobe0_centsError, // 20
  strobe1_amp,
  strobe1_position,
  strobe1_velocity,
  strobe1_acceleration,
  strobe1_centsError,
  strobe2_amp,
  strobe2_position,
  strobe2_velocity,
  strobe2_acceleration,
  strobe2_centsError,
  strobe3_amp,
  strobe3_position,
  strobe3_velocity,
  strobe3_acceleration,
  strobe3_centsError,
  strobe4_amp,
  strobe4_position,
  strobe4_velocity,
  strobe4_acceleration,
  strobe4_centsError,
}

/*
export function NormalNoteToDatunerNote(
  normalNoteIdx: number,
  normalNoteOctave: number,
  offset: number = DatunerNotes.E_NOTE_C,
) {
  let n = normalNoteIdx + offset;
  let o = normalNoteOctave;
  if (n >= 12) {
    n = n - 12;
    o += 1;
  }
  return { n: n, o: o };
}

export function DatunerNoteToNormalNote(
  datunerNote: number,
  datunerOct: number,
  offset: number = DatunerNotes.E_NOTE_C,
) {
  let n = datunerNote + NormalNotes.E_NOTE_A;
  let o = datunerOct - 1;
  if (n >= 12) {
    n = n - 12;
    o += 1;
  }
  return { n: n, o: o };
}
*/

export function NormalNoteToDatunerNote(
  note: number,
  octave: number,

  scaleOffset: number = DatunerNotes.E_NOTE_C,
) {
  let n = (Math.round(note) % 12) + scaleOffset;
  let o = octave - 1;
  if (n >= 12) {
    n = n - 12;
    o += 1;
  }
  return { n: n, o: o };
}

// Convert DaTuner note (with offset at note A) to another scale (example, 0 index at C)
export function DatunerNoteToNormalNote(
  note: number,
  octave: number,
  scaleOffset: number = DatunerNotes.E_NOTE_C,
) {
  // If A..scaleOffset-1, then decrease octave by 1
  let n = (Math.round(note) % 12) - scaleOffset;
  let o = octave + 1;
  if (n < 0) {
    o--;
    n += 12;
  }
  return { n: n, o: o };
}

// Length of a StrobeEvt
export const StrobeEvtLen: number = PollResults.strobe0_centsError - PollResults.strobe0_amp + 1;

export interface StrobeEvt {
  amp: number; // 0..1, amplitude (brightness) of the strobe.
  position: number; // 0..1, corresponding to the most recent phase of the strobe.
  velocity: number; // Current change in position per sample.
  acceleration: number; // Current change in velocity per sample.
  centsError: number; // Get the cents error vs the locked frequency.
}

export interface ThreshEvt {
  analysisActive: boolean;
  signalDBFS: number;
  thresholdDBFS: number;
  noiseFloorDBFS: number;
  strobesActive: number;
  strobes: Array<StrobeEvt>;
}

export const ThreshEvtDefault: ThreshEvt = {
  analysisActive: false,
  signalDBFS: -100,
  thresholdDBFS: -100,
  noiseFloorDBFS: -100,
  strobesActive: 0,
  strobes: new Array<StrobeEvt>(0),
};
export interface NoteEvt {
  note: string;
  octave: number;
  frequency: number;
  centsError: number;
}

export const NoteEvtDefault: NoteEvt = {
  note: '',
  octave: 0,
  frequency: 440,
  centsError: 0,
};

class HeapAllocator {
  dataPtr?: any;
  dataHeap?: Uint8Array;
  _strobe?: any;

  constructor(_strobe: any, flt32Arr: null | Float32Array, int32Arr: null | Int32Array) {
    this._strobe = _strobe;
    const nDataBytes = flt32Arr
      ? flt32Arr.length * flt32Arr.BYTES_PER_ELEMENT
      : int32Arr
      ? int32Arr.length * int32Arr.BYTES_PER_ELEMENT
      : 0;
    this.dataPtr = _strobe._malloc(nDataBytes);
    this.dataHeap = new Uint8Array(_strobe.HEAPU8.buffer, this.dataPtr, nDataBytes);

    if (this.dataHeap) {
      if (flt32Arr) {
        // Copy the contents of buffer.buffer into tmp_databuffer
        this.dataHeap.set(new Uint8Array(flt32Arr.buffer));
      } else if (int32Arr) {
        this.dataHeap.set(new Uint8Array(int32Arr.buffer));
      }
    }
  }

  getPtr(): any {
    return this.dataPtr;
  }

  free() {
    this._strobe._free(this.dataPtr);
  }
}

class StroboProCpp {
  _strobe: any = null;
  isInitialized: boolean = false;
  _tunerPromise: Promise<any> | null = null;
  evt_func?: (noteEvt?: NoteEvt, threshEvt?: ThreshEvt) => void = undefined;

  static inst(): StroboProCpp {
    if (null === _inst) {
      _inst = new StroboProCpp();

      _inst._tunerPromise = new Promise((resolve, reject) => {
        const initializedPromise = tuner.CreateMyStrobeTuner();
        initializedPromise
          .then((strobe: any) => {
            if (_inst) {
              _inst._strobe = strobe;
            }

            if (_inst) {
              try {
                // Initialize wrapper WITHOUT a DC removal frequency - we implement it with WebAudio for performance reasons.
                // 512 FFT size, no oversampling, decimation 1x because we do that externally.
                _inst.WrapperInit(48000, 1024, 2, 1, 40, 41, false);
                resolve('ok');
              } catch (e) {
                alert('Exception while initializing detection algorithm:' + e);
                reject('Exception while initializing detection algorithm:' + e);
              }
            }
          })
          .catch(() => {
            console.log('Error creating strobe tuner.');
          });
      });
    }

    const ia: any = _inst;
    const theInst: StroboProCpp = ia;
    return theInst;
  }

  //int WrapperInit(int fs, int fftSize, int oversampling, int decimation, float dcRemoveFreq);
  WrapperInit(
    fs: number = 48000,
    fftSize: number = 1024,
    oversampling: number = 4,
    decimation: number = 4,
    dcRemoveFreq: number = 41,
    minimumAnalysisFreq: number = 55,
    waitForPromise: boolean = true,
  ) {
    const doIt = () => {
      this._strobe._WrapperInit(
        fs,
        fftSize,
        oversampling,
        decimation,
        dcRemoveFreq,
        minimumAnalysisFreq,
      );
      setTimeout(() => {
        this._strobe._WrapperChangeFftTriggerUpDownRate(0.2, 4);
      }, 10000);
      this.isInitialized = true;
    };
    if (!waitForPromise) {
      doIt();
      //dbg.log('WrapperInit completed.');
    } else if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        doIt();
      });
    }
  }

  WrapperSetCallback(evt_func?: (noteEvt?: NoteEvt, threshEvt?: ThreshEvt) => void) {
    this.evt_func = evt_func;
  }

  WrapperClearCallback(evt_func: (noteEvt?: NoteEvt, threshEvt?: ThreshEvt) => void) {
    if (evt_func === this.evt_func) {
      this.evt_func = undefined;
    }
  }

  //int WrapperChangeFS(int fs);
  WrapperChangeFs(fs: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeFS(fs);
      });
    }
  }

  WrapperChangeReferenceFreq(refFreq: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeReferenceFreq(refFreq);
        //dbg.log('WrapperChangeReferenceFreq completed.');
      });
    }
  }

  //int WrapperChangeManualSensDb(float manDb);
  WrapperChangeManualSensDb(manDb: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeManualSensDb(manDb);
      });
    }
  }

  //int WrapperChangeAutoSensDb(float autoDb);
  WrapperChangeAutoSensDb(autoDb: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeAutoSensDb(autoDb);
      });
    }
  }

  //int WrapperUpdateNotes(
  //  int numNotes, float freqHzRel440Ary[], int noteAry[],
  //  int octaveAry[], int uniqueTagAry[]);
  _WrapperUpdateNotes(
    numNotes: number,
    freqHzRel440Ary: number,
    noteAry: number,
    octaveAry: number,
    uniqueTagAry: number,
  ): number {
    return this._strobe._WrapperUpdateNotes(
      numNotes,
      freqHzRel440Ary,
      noteAry,
      octaveAry,
      uniqueTagAry,
    );
  }

  WrapperUpdateNotes(
    freqHzRel440Ary: Float32Array,
    noteAry: Int32Array,
    octaveAry: Int32Array,
    uniqueTagAry: Int32Array,
  ): number {
    let rval = 0;
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        const freqs = new HeapAllocator(this._strobe, freqHzRel440Ary, null);
        const notes = new HeapAllocator(this._strobe, null, noteAry);
        const octaves = new HeapAllocator(this._strobe, null, octaveAry);
        const tags = new HeapAllocator(this._strobe, null, uniqueTagAry);

        rval = this._strobe._WrapperUpdateNotes(
          freqHzRel440Ary.length,
          freqs.getPtr(),
          notes.getPtr(),
          octaves.getPtr(),
          tags.getPtr(),
        );
        freqs.free();
        notes.free();
        octaves.free();
        tags.free();
      });
    }
    return rval;
  }

  //int WrapperSendAudioPcm16(int16_t *pArr, int length);
  WrapperSendAudioPcm16(/*int16_t *pArr, int length*/) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperSendAudioPcm16();
      });
    }
  }

  //int WrapperSendAudioFloat(float *pArr, int length);
  _WrapperSendAudioFloat(pArr: number, length: number): number {
    if (!this._strobe) {
      return 0;
    } else {
      return this._strobe._WrapperSendAudioFloat(pArr, length);
    }
  }

  //int WrapperPoll(float *pResult, int length);
  WrapperPoll = (pResult: any, length: any): number => {
    const rval: number = this._strobe._WrapperPoll(pResult, length);
    return rval;
  };

  my_wrapper_report_result(resultArr: Float32Array) {
    if (resultArr[PollResults.gotSigEvt]) {
      if (this.evt_func) {
        const numStrobesActive = resultArr[PollResults.strobesActive];
        let idx = PollResults.strobe0_amp;
        const strobes: Array<StrobeEvt> = new Array<boolean>(numStrobesActive)
          .fill(true)
          .map((_val: boolean, _index: number) => {
            let val: StrobeEvt = {
              amp: resultArr[idx + 0],
              position: resultArr[idx + 1],
              velocity: 4 * resultArr[idx + 2],
              acceleration: resultArr[idx + 3],
              centsError: resultArr[idx + 4],
            };
            idx += StrobeEvtLen;
            return val;
          });

        const threshEvt: ThreshEvt = {
          analysisActive: resultArr[PollResults.analysisActive] > 0,
          signalDBFS: resultArr[PollResults.dBFSSignal],
          thresholdDBFS: resultArr[PollResults.dbFSThreshold],
          noiseFloorDBFS: resultArr[PollResults.dbFSNoiseFloor],
          strobesActive: resultArr[PollResults.strobesActive],
          strobes: strobes,
        };
        // Note, this new code crashes for Chris under Chrome!
        this.evt_func(undefined, threshEvt);
      }
    }
    if (resultArr[PollResults.gotResult]) {
      let note = '';
      let octave = resultArr[PollResults.octave]; // increment octaves of notes from C- G#, we're using a C based octave index, with middle C as C4
      let noteIdx = resultArr[PollResults.note];
      const no = DatunerNoteToNormalNote(noteIdx, octave);
      note = SHARP_NOTE_NAMES[no.n % 12];
      octave = no.o;

      if (this.evt_func) {
        // Note, this new code crashes for Chris under Chrome!
        this.evt_func(
          {
            note: note,
            octave: octave,
            frequency: resultArr[PollResults.freqHz],
            centsError: resultArr[PollResults.centsError],
          },
          undefined,
        );
      }
    }
  }

  // Globals used to prevent lots of allocating while running.
  dataPtrLen = 1;
  dataPtr: any = null; //= Module._malloc(1);
  dataHeap: null | Uint8Array = null;

  WrapperSendAudio(buffer: Float32Array): number {
    let rval = 0;
    if (this._strobe) {
      //this._strobe._WrapperSendAudioFloat(pArr, length);
      // Get data byte size, allocate memory on Emscripten heap, and get pointer
      var nDataBytes = buffer.length * buffer.BYTES_PER_ELEMENT;

      if (this.dataPtrLen !== nDataBytes) {
        this.dataPtrLen = nDataBytes;
        if (this.dataPtr) {
          this._strobe._free(this.dataPtr);
          this.dataPtr = null;
        }
        this.dataPtr = this._strobe._malloc(nDataBytes);
        this.dataHeap = new Uint8Array(this._strobe.HEAPU8.buffer, this.dataPtr, nDataBytes);
      }

      if (this.dataHeap) {
        // Copy the contents of buffer.buffer into tmp_databuffer
        this.dataHeap.set(new Uint8Array(buffer.buffer));

        // Call function and get result
        //this.WrapperSendAudioFloat(this.dataHeap.byteOffset, buffer.length);
        rval = this._strobe._WrapperSendAudioFloat(this.dataHeap.byteOffset, buffer.length);
      }
    }
    return rval;
  }

  MAX_STROBES = 5;
  STROBE_LENGTH = PollResults.strobe0_centsError - PollResults.strobe0_amp + 1;
  RESULT_LENGTH = PollResults.strobesActive + this.STROBE_LENGTH * this.MAX_STROBES + 1;

  wr_resultArr: Float32Array = new Float32Array(this.RESULT_LENGTH);
  wr_uint8ArrEncompassingHeap: Uint8Array | null = null; // = new Uint8Array(this._strobe.HEAPU8.buffer, this.wr_resultPtr, this.wr_resultBytes);

  MyWrapperPoll = () => {
    var lastPollResult = -2;
    var thisPollResult = -1;

    if (!this.wr_uint8ArrEncompassingHeap) {
      const wr_resultBytes: number = this.RESULT_LENGTH * this.wr_resultArr.BYTES_PER_ELEMENT;
      // Do a "malloc" on the internal heap. Will return an "offset" into the HEAPU8 buffer of _strobe.
      const wr_resultHeapOffset = this._strobe._malloc(wr_resultBytes);

      // Get an array which incorporates the malloc'd data.
      this.wr_uint8ArrEncompassingHeap = new Uint8Array(
        this._strobe.HEAPU8.buffer,
        wr_resultHeapOffset,
        wr_resultBytes,
      );
      // Set the value of the arrEncompassingHeap to 0
      this.wr_uint8ArrEncompassingHeap.set(new Uint8Array(this.wr_resultArr.buffer));
    }

    if (this.wr_uint8ArrEncompassingHeap) {
      while (lastPollResult !== thisPollResult && thisPollResult !== 0) {
        lastPollResult = thisPollResult;

        this.wr_resultArr[PollResults.gotResult] = 0;
        thisPollResult = this.WrapperPoll(
          this.wr_uint8ArrEncompassingHeap.byteOffset,
          this.wr_resultArr.length,
        );

        // Initialize the result
        var result = new Float32Array(
          this.wr_uint8ArrEncompassingHeap.buffer,
          this.wr_uint8ArrEncompassingHeap.byteOffset,
          this.wr_resultArr.length,
        );

        if (result[PollResults.gotResult] || result[PollResults.gotSigEvt]) {
          this.my_wrapper_report_result(result);
        }
      }
    }
  };

  WrapperAddSamples(buffer: Float32Array) {
    if (this.WrapperSendAudio(buffer)) {
      this.MyWrapperPoll();
    }
  }

  hi() {
    //dbg.log('hi');
  }
}

export { StroboProCpp };
