import { dbg } from '../debug/debug';

/*
C -1 0 note 0, octave -1
C#-1 1 note 1, octave -1
D -1 2
D#-1 3
E -1 4
F -1 5
F#-1 6
G -1 7
G#-1 8

A -1 9  13.75Hz
A#-1 10
B -1 11
C  0 12 16.351597 = A0 * 2^(-57/12) = 440Hz * 2^(-57/12) = 440Hz * 2^(-4 - 9/12)
C# 0 13
D  0 14
D# 0 15
E  0 16
F  0 17
F# 0 18
G  0 19
G# 0 20

A0  21 27.5Hz
A#0 22
B0  23
C1  24
C#1 25
D1  26
D#1 27
E1  28
F1  29
F#1 30
G1  31
G#1 32
A1  33

...
C4  60
...
A4  69


// https://en.wikipedia.org/wiki/Scientific_pitch_notation

Table of note frequencies
Piano Keyboard
An 88-key piano, with the octaves numbered and Middle C (cyan) and A440 (yellow) highlighted.
The table below gives notation for pitches based on standard piano key frequencies: standard concert pitch and twelve-tone equal temperament. When a piano is tuned to just intonation, C4 refers to the same key on the keyboard, but a slightly different frequency. Keys which do not appear on any piano (medium gray) or only on an extended 108-key piano (light gray) are highlighted.

Fundamental frequency in hertz (MIDI note number)
Octave
Note
−1	0	1	2	3	4	5	6	7	8	9	10
C	8.175799 (0)	16.35160 (12)	32.70320 (24)	65.40639 (36)	130.8128 (48)	261.6256 (60)	523.2511 (72)	1046.502 (84)	2093.005 (96)	4186.009 (108)	8372.018 (120)	16744.04    
C♯/D♭	8.661957 (1)	17.32391 (13)	34.64783 (25)	69.29566 (37)	138.5913 (49)	277.1826 (61)	554.3653 (73)	1108.731 (85)	2217.461 (97)	4434.922 (109)	8869.844 (121)	17739.69    
D	9.177024 (2)	18.35405 (14)	36.70810 (26)	73.41619 (38)	146.8324 (50)	293.6648 (62)	587.3295 (74)	1174.659 (86)	2349.318 (98)	4698.636 (110)	9397.273 (122)	18794.55    
E♭/D♯	9.722718 (3)	19.44544 (15)	38.89087 (27)	77.78175 (39)	155.5635 (51)	311.1270 (63)	622.2540 (75)	1244.508 (87)	2489.016 (99)	4978.032 (111)	9956.063 (123)	19912.13    
E	10.30086 (4)	20.60172 (16)	41.20344 (28)	82.40689 (40)	164.8138 (52)	329.6276 (64)	659.2551 (76)	1318.510 (88)	2637.020 (100)	5274.041 (112)	10548.08 (124)	21096.16    
F	10.91338 (5)	21.82676 (17)	43.65353 (29)	87.30706 (41)	174.6141 (53)	349.2282 (65)	698.4565 (77)	1396.913 (89)	2793.826 (101)	5587.652 (113)	11175.30 (125)	22350.61    
F♯/G♭	11.56233 (6)	23.12465 (18)	46.24930 (30)	92.49861 (42)	184.9972 (54)	369.9944 (66)	739.9888 (78)	1479.978 (90)	2959.955 (102)	5919.911 (114)	11839.82 (126)	23679.64    
G	12.24986 (7)	24.49971 (19)	48.99943 (31)	97.99886 (43)	195.9977 (55)	391.9954 (67)	783.9909 (79)	1567.982 (91)	3135.963 (103)	6271.927 (115)	12543.85 (127)	25087.71    
A♭/G♯	12.97827 (8)	25.95654 (20)	51.91309 (32)	103.8262 (44)	207.6523 (56)	415.3047 (68)	830.6094 (80)	1661.219 (92)	3322.438 (104)	6644.875 (116)	13289.75    	26579.50    
A	13.75000 (9)	27.50000 (21)	55.00000 (33)	110.0000 (45)	220.0000 (57)	440.0000 (69)	880.0000 (81)	1760.000 (93)	3520.000 (105)	7040.000 (117)	14080.00    	28160.00    
B♭/A♯	14.56762 (10)	29.13524 (22)	58.27047 (34)	116.5409 (46)	233.0819 (58)	466.1638 (70)	932.3275 (82)	1864.655 (94)	3729.310 (106)	7458.620 (118)	14917.24    	29834.48    
B	15.43385 (11)	30.86771 (23)	61.73541 (35)	123.4708 (47)	246.9417 (59)	493.8833 (71)	987.7666 (83)	1975.533 (95)	3951.066 (107)	7902.133 (119)	15804.27    	31608.53    
Mathematically, given the number n of semitones above middle C, the fundamental frequency in hertz is given by {\displaystyle 440\cdot 2^{(n-9)/12}}{\displaystyle 440\cdot 2^{(n-9)/12}} (see twelfth root of two). Given the MIDI NoteOn number m, the frequency of the note is normally {\displaystyle 440\cdot 2^{(m-69)/12}}{\displaystyle 440\cdot 2^{(m-69)/12}} Hz, using standard tuning.


*/
export const ONE_OVER_LN_2 = 1.0 / Math.log(2.0);

// Juce and other MIDI software assigns middle-C to octave 3.
export const MIDDLE_C_DEFAULT_OCTAVE = 4;

// 12 is C0
export const MIDI_IDX_C0 = 12;
export const MIDI_IDX_C4 = 60;
export const MIDI_IDX_A4 = 69;
// NoteAndOctave scale note and octave of 0,0 ==> A4 440Hz.
// A4 is at midi index 69.
export const A4_MIDI_IDX_OFFSET = 69 - 12;

// A4 Ref --> C0 Ref
export const REF_FREQ_CHROMATIC_CONVERT_TO_C0 = Math.pow(
  2,
  -A4_MIDI_IDX_OFFSET / 12.0, // -57 / 12
);

export enum MidiEventTypes {
  Note_Off = 0x8, // note number	velocity
  Note_On = 0x9, // 	note number	velocity
  Note_Aftertouch = 0xa, // 	note number	aftertouch value
  Controller = 0xb, // 	controller number	controller value
  Program_Change = 0xc, // 	program number	not used
  Channel_Aftertouch = 0xd, // 	aftertouch value	not used
  Pitch_Bend = 0xe, // 	pitch value (LSB)	pitch value (MSB)
  End = 0xff,
}

export type MidiEventEntry = {
  deltaTime: number;
  type: number;
  metaType?: number;
  channel: number;
  data?: Array<number>;
};

export type TrackEntry = {
  event: Array<MidiEventEntry>;
};

export type ParseOut = {
  formatType: number;
  tracks: number;
  track: Array<TrackEntry>;
  timeDivision: number;
};

export type NoteEvt = {
  ts: number;
  notesOn: Array<number>;
};

// ----------------------------------------------------------------------------
export function NoteArrayPrintDebug(n: Array<number>): string {
  let str = 'invalid';
  if (n && n.length === 4) {
    str = '';
    for (let w = 0; w < 4; w++) {
      const word = n[w];
      for (let b = 0; b < 32; b++) {
        const mask = 1 << b;
        const bit = mask & word;
        if (0 === bit) {
          str += ' ';
        } else {
          str += '*';
        }
      }
    }
  }
  return str;
}

// ----------------------------------------------------------------------------
export function NoteEventPrintDebug(n: NoteEvt, numNotes: number = 88): string {
  let arr = new Array<boolean>(numNotes).fill(false);
  n.notesOn.forEach((n: number) => {
    if (n < numNotes) {
      arr[n] = true;
    }
  });
  let out = '';
  arr.forEach((state: boolean) => {
    out += state ? '*' : ' ';
  });

  return out;
}

// Can be used as input to the midi fetcher
export type Song64 = {
  file: string;
  songname: string;
  gameArr?: Array<NoteEvt>;
  midiBase64?: string;
  midiJson?: ParseOut;
};

export enum NoteIdx {
  C = 0,
  Cs = 1,
  D = 2,
  Ds = 3,
  E = 4,
  F = 5,
  Fs = 6,
  G = 7,
  Gs = 8,
  A = 9,
  As = 10,
  B = 11,
}

export const SHARP_NOTE_NAMES = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
export const FLAT_NOTE_NAMES = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
export const FlatNotesStr = ['C#', 'D#', 'F#', 'G#', 'A#'];
export const SharpNotesStr = ['Db', 'Eb', 'Gb', 'Ab', 'Bb'];
export const FlatSharpIdxs = [1, 3, 6, 8, 10];
export const NonFlatNotesStr = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
export const NonFlatNotesIdxs = [0, 2, 4, 5, 7, 9, 11];

// ----------------------------------------------------------------------------
// If you want to get the name of a note, but your indexes start at A == 0, use this function.
export function getAOffsetName(idx: number): string {
  const aOffset = (idx + NoteIdx.A) % 12;
  return SHARP_NOTE_NAMES[aOffset];
}

// ----------------------------------------------------------------------------
// The chromatic functions start from C0 at 16.351Hz.
export type NoteAndOctave = {
  note: number;
  octave: number;
};

// ----------------------------------------------------------------------------
export function ParseNote(str: string): NoteAndOctave {
  let noteSymbolStr = str.slice(0, 1);
  const noteSharpFlat = str.slice(1, 2);
  let octaveNum = 0;
  let noteIdx = -1;
  if (noteSharpFlat === '#' || noteSharpFlat === 'b' || noteSharpFlat === 'B') {
    // Is a flat note or sharp note.
    const octaveStr = str.slice(2);
    octaveNum = parseInt(octaveStr, 10);
    noteSymbolStr = str.slice(0, 2);
    if (noteSharpFlat === '#') {
      noteIdx = SHARP_NOTE_NAMES.findIndex((value: string, index: number) => {
        return value.toUpperCase() === noteSymbolStr.toUpperCase();
      });
    } else {
      noteIdx = FLAT_NOTE_NAMES.findIndex((value: string, index: number) => {
        return value.toUpperCase() === noteSymbolStr.toUpperCase();
      });
    }
  } else {
    const octaveStr = str.slice(1);
    octaveNum = parseInt(octaveStr, 10);
    noteIdx = SHARP_NOTE_NAMES.findIndex((value: string, index: number) => {
      return value.toUpperCase() === noteSymbolStr.toUpperCase();
    });
  }
  const c: NoteAndOctave = {
    note: noteIdx,
    octave: octaveNum,
  };
  return c;
}

// ----------------------------------------------------------------------------
export function NoteAndOctaveToMidiIdx(c: NoteAndOctave) {
  return c.octave * 12 + c.note + MIDI_IDX_C0;
}

// ----------------------------------------------------------------------------
// Converts a raw note (not % 12) to note and octave.
export function NoteIdxToNoteAndOctave(_noteIdx: number): NoteAndOctave {
  const noteIdx = Math.round(_noteIdx);
  let octave = Math.floor(noteIdx / 12);
  let note = noteIdx - octave * 12;

  note = note === 0 ? 0 : note < 0 ? note + 12 : note;

  const note2 = Math.floor(note % 12);
  if (note !== note2) {
    dbg.log(
      'noteIdxToNoteAndOctave: ' +
        _noteIdx +
        ' Doing floor on note of ' +
        note +
        ' resulted in ' +
        note2,
    );
    note = note2;
  }

  return { note: note, octave: octave };
}

// ----------------------------------------------------------------------------
export function MidiIdxToNoteAndOctave(midiIdx: number): NoteAndOctave {
  return NoteIdxToNoteAndOctave(midiIdx - MIDI_IDX_C0);
}

// ----------------------------------------------------------------------------
export function IsSharp(note: NoteIdx): boolean {
  return note === NoteIdx.Cs ||
    note === NoteIdx.Ds ||
    note === NoteIdx.Fs ||
    note === NoteIdx.Gs ||
    note === NoteIdx.As
    ? true
    : false;
}

// ----------------------------------------------------------------------------
export function NoteIdxToString(note: NoteIdx, octave?: number): string {
  let c = '';
  const s = IsSharp(note) ? '#' : '';
  const o = octave !== undefined ? octave.toString() : '';
  switch (note) {
    case NoteIdx.C:
    case NoteIdx.Cs:
      c = 'C';
      break;
    case NoteIdx.D:
    case NoteIdx.Ds:
      c = 'D';
      break;
    case NoteIdx.E:
      c = 'E';
      break;
    case NoteIdx.F:
    case NoteIdx.Fs:
      c = 'F';
      break;
    case NoteIdx.G:
    case NoteIdx.Gs:
      c = 'G';
      break;
    case NoteIdx.A:
    case NoteIdx.As:
      c = 'A';
      break;
    case NoteIdx.B:
      c = 'B';
      break;
    default:
      c = '?';
      break;
  }

  return c + s + o;
}

export type MidiNote = {
  note: NoteIdx;
  octave: number;
  midiIdx: number;
  isSharp: boolean;
};

export type GetNoteErrorResult = {
  note: number;
  octave: number;
  errCents: number;
  freqError: number;
  idealFreq: number;
};

// ----------------------------------------------------------------------------
export function GetNearestNoteAndError(
  frequencyHz: number,
  _referenceFreq = 440,
): GetNoteErrorResult {
  const refFreqC0 = _referenceFreq * REF_FREQ_CHROMATIC_CONVERT_TO_C0;
  let errCents = 0;

  let freqError = 0;
  frequencyHz = Math.max(0.00000000001, frequencyHz);
  const n = 12.0 * Math.log(frequencyHz / refFreqC0) * ONE_OVER_LN_2;
  const nearestN = Math.round(n);
  const { note, octave } = NoteIdxToNoteAndOctave(nearestN);

  // Calculate frequency error in Hz and cents.
  const noteFreq = refFreqC0 * Math.pow(2.0, nearestN / 12.0);
  const noteRatio = frequencyHz / noteFreq;
  freqError = frequencyHz - noteFreq;

  if (noteRatio <= 0.0) {
    // Prevent divide by or log of zero.
    errCents = 0.0;
  } else {
    const oldErrCents = 1200.0 * Math.log(noteRatio) * ONE_OVER_LN_2;
    errCents = oldErrCents;
  }

  return {
    errCents: errCents,
    freqError: freqError,
    idealFreq: noteFreq,
    note: note,
    octave: octave,
  };
}

// ----------------------------------------------------------------------------
export function NoteAndOctaveGetFreqFromNoteAndError(
  c: NoteAndOctave,
  errCents: number = 0,
  refFreqHz = 440,
) {
  const refFreqC0 = refFreqHz * REF_FREQ_CHROMATIC_CONVERT_TO_C0;
  const noteIdx = c.octave * 12 + c.note;
  const rval = refFreqC0 * Math.pow(2.0, (noteIdx * 100.0 + errCents) / 1200.0);
  return rval;
}

// ----------------------------------------------------------------------------
export function NoteAndOctaveNoteToPerfectFreq(c: NoteAndOctave, refFreqHz = 440): number {
  const refFreqC0 = refFreqHz * REF_FREQ_CHROMATIC_CONVERT_TO_C0;
  const noteIdx = c.octave * 12 + c.note;
  const freq = refFreqC0 * Math.pow(2.0, noteIdx / 12.0);
  return freq;
}

// ----------------------------------------------------------------------------
export function MidiToNote(midiIdx: number): MidiNote {
  midiIdx %= 128;
  const { note, octave } = MidiIdxToNoteAndOctave(midiIdx);
  const rval = {
    note: note,
    octave: octave,
    midiIdx: midiIdx,
    isSharp:
      note === NoteIdx.Cs ||
      note === NoteIdx.Ds ||
      note === NoteIdx.Fs ||
      note === NoteIdx.Gs ||
      note === NoteIdx.As,
  };

  return rval;
}

// ----------------------------------------------------------------------------
export function NoteToMidiIdx(_note: number, _octave: number): number {
  const { note, octave } = NoteIdxToNoteAndOctave(_note + 12 * _octave);
  return A4_MIDI_IDX_OFFSET + 12 * octave + note;
}

// ----------------------------------------------------------------------------
export function MidiNoteToString(note: MidiNote) {
  return NoteIdxToString(note.note, note.octave);
}

// ----------------------------------------------------------------------------
export function MidiIdxToString(midiIdx: number): string {
  const note = MidiToNote(midiIdx);
  return MidiNoteToString(note);
}

// ----------------------------------------------------------------------------
function isPositiveAndBelow(valueToTest: number, upperLimit: number): boolean {
  return valueToTest < upperLimit;
}

// ----------------------------------------------------------------------------
// Reference for comparison with MidiIdxToString
export function MidiIdxToString2(
  _midiIdx: number,
  useSharps: boolean = true,
  includeOctaveNumber: boolean = true,
  octaveNumForMiddleC: number = MIDDLE_C_DEFAULT_OCTAVE,
): string {
  let s: string = '';
  const midiIdx = Math.floor(_midiIdx);
  if (isPositiveAndBelow(midiIdx, 128)) {
    const mod = midiIdx % 12;
    s = useSharps ? SHARP_NOTE_NAMES[mod] : FLAT_NOTE_NAMES[mod];

    if (includeOctaveNumber) {
      const octave = Math.floor(midiIdx / 12) + (octaveNumForMiddleC - 5);
      s = s + octave.toFixed(0);
    }
  }

  return s;
}

// -------------------------------------
// Piano support
export type PianoKey = {
  midiIdx: number;
  midiNote: MidiNote;
  text: string;
  isBlack: boolean;
  keyIdx: number; // 0..88 (standard piano)
  whiteKeyIdx?: number;
  blackKeyIdx?: number;
};

// Used temporarily to generate piano keys
const allPianoKeyIndexes = new Array<number>(88).fill(1).map((_, i: number) => i);

// ----------------------------------------------------------------------------
// Standard
export const StandardPianoKeysArr: Array<PianoKey> = allPianoKeyIndexes.map(
  (value: number, keyIdx: number) => {
    const offset = 21;
    const midiIdx = keyIdx + offset;
    const note = MidiToNote(midiIdx);
    const black = note.isSharp;
    let pianoKey: PianoKey = {
      keyIdx: keyIdx,
      text: MidiNoteToString(note),
      midiNote: note,
      midiIdx: midiIdx,
      isBlack: black,
    };
    const set = Math.floor(pianoKey.keyIdx / 12);
    const key = Math.floor(pianoKey.keyIdx % 12);
    if (black) {
      pianoKey.blackKeyIdx = set * 5 + key; // number of black keys per set
    } else {
      pianoKey.whiteKeyIdx = set * 7 + key; // number of white keys per set.
    }
    return pianoKey;
  },
);

// ----------------------------------------------------------------------------
export function MidiNoteToPianoKey(midiIdx: number): PianoKey {
  if (midiIdx >= 0 && midiIdx < StandardPianoKeysArr.length) {
    return StandardPianoKeysArr[midiIdx];
  } else {
    return StandardPianoKeysArr[0];
  }
}
// END piano

// Create a lookup table.
export type MapElement = {
  octave: number;
  note: NoteIdx;
  str: string;
  freq: number;
  freqDiv440: number;
};

// ----------------------------------------------------------------------------
export const MidiIdxToFreqMap = new Array(128).fill(true).map((_value: any, midiIdx: number) => {
  const { note, octave } = MidiIdxToNoteAndOctave(midiIdx);
  const c = MidiIdxToNoteAndOctave(midiIdx);
  const freq = NoteAndOctaveNoteToPerfectFreq(c);
  const m: MapElement = {
    note: note,
    octave: octave,
    str: NoteIdxToString(note),
    freq: freq,
    freqDiv440: freq / 440.0,
  };
  if (c.note !== m.note || c.octave !== m.octave) {
    console.warn('Bad conversion.');
  }
  return m;
});
