export type AudioChunkerCallback = (pArr: Float32Array) => void;
export class AudioChunker {
  outputArray: Float32Array;
  inputIndex: number = 0;
  outputIndex: number = 0;
  decimation = 1;
  callback: AudioChunkerCallback = (pArr: Float32Array) => {};

  constructor(
    outputBufSize: number = 256,
    decimationFactor: number = 1,
    callback?: AudioChunkerCallback,
  ) {
    this.outputArray = new Float32Array(outputBufSize).fill(0);
    this.decimation = decimationFactor <= 1 ? 1 : decimationFactor;
    this.callback = callback ? callback : this.callback;
    this.reset();
  }

  reInit(
    outputBufSize: number = 256,
    decimationFactor: number = 1,
    callback?: AudioChunkerCallback,
  ) {
    this.outputArray = new Float32Array(outputBufSize).fill(0);
    this.decimation = decimationFactor <= 1 ? 1 : decimationFactor;
    this.callback = callback ? callback : this.callback;
    this.reset();
  }

  reset() {
    this.inputIndex = 0;
    this.outputIndex = 0;
  }

  chunkData(dataToDecimate: Float32Array) {
    let done = false;

    do {
      while (
        this.inputIndex < dataToDecimate.length &&
        this.outputIndex < this.outputArray.length
      ) {
        this.outputArray[this.outputIndex++] = dataToDecimate[this.inputIndex];
        this.inputIndex += this.decimation;
      }
      if (this.outputIndex >= this.outputArray.length) {
        this.callback(this.outputArray);
        this.outputIndex -= this.outputArray.length;
      }
      if (this.inputIndex >= dataToDecimate.length) {
        this.inputIndex -= dataToDecimate.length;
        done = true;
      }
    } while (!done);
  }
}

/*
  AudioChunker(AudioOutFnPtr cbFn, void* const pCbUserData, const int bufSize, const int decimationRatio)
    : mDecimationBufSize(bufSize + decimationRatio)
    , mDecimationRatio(decimationRatio)
    , mDecimationBuf(new T[ mDecimationBufSize ])
    , mDecimationSampsRemain(0)
    , mCbFn(cbFn)
    , mCbFnDataPtr(pCbUserData) {
  }

  ~AudioChunker() {
    delete[] mDecimationBuf;
  }


  // Passes the audio from the input to the callback
  void passAudio(
    const T* const pSamps,
    const int numSamps,
    AudioOutFnPtr cb = nullptr,
    void* pUserData  = nullptr) {
    cb        = (cb == nullptr) ? mCbFn : cb;
    pUserData = (pUserData) ? pUserData : this->mCbFnDataPtr;
    if (nullptr == cb) {
      return;
    }
    LOG_ASSERT_HPP(mDecimationBufSize > (numSamps + mDecimationSampsRemain));
    const int availableSamps = mDecimationSampsRemain + numSamps;
    const int evenSamps =
      (availableSamps / mDecimationRatio) * mDecimationRatio;
    if ((0 == mDecimationSampsRemain) && (evenSamps == numSamps)) {
      // Directly pass the audio
      cb(pUserData, pSamps, numSamps);
    } else {
      // We need to put the remaining samples first, followed by our samples.
      const int remainingAfter = availableSamps - evenSamps;
      const int sampsToCopy    = numSamps - remainingAfter;
      memcpy(
        &mDecimationBuf[ mDecimationSampsRemain ],
        &pSamps[ 0 ],
        sampsToCopy * sizeof(T));

      cb(pUserData, mDecimationBuf, evenSamps);

      // Save the samples at the end of the buffer.
      memcpy(&mDecimationBuf[ 0 ], &pSamps[ sampsToCopy ], remainingAfter * sizeof(T));
      mDecimationSampsRemain = remainingAfter;
    }
  }

  const int mDecimationBufSize;
  const int mDecimationRatio;


private:
  AudioChunker() {
  }

  T* const mDecimationBuf;
  int mDecimationSampsRemain;
  const AudioOutFnPtr mCbFn;
  void* const mCbFnDataPtr;
};

#endif
*/
