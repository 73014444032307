// tslint:disable-next-line
// @ts-ignore: No definitions
import * as tuner from './tuner.js';

const CreateMyStrobeTuner = (): Promise<any> => {
  return tuner.default();
};
//const CreateMyStrobeTuner = tuner.CreateMyStrobeTuner;
//dbg.log("CreateMyStrobeTuner:", CreateMyStrobeTuner);
export { tuner, CreateMyStrobeTuner };
