import { combineReducers, createStore, applyMiddleware } from 'redux';
import { getItem, setItem, TingsToStore } from './async_storage';
import createSagaMiddleware from 'redux-saga';
import {
  StroboProCpp,
  StrobeEvt,
  ThreshEvt,
  NoteEvt,
  NoteEvtDefault,
  ThreshEvtDefault,
} from './wrapper/js_wrapper';
import {
  NoteMappingMiddleWare,
  NoteMappingSideEffects,
  TuningElement,
} from './instruments/note_mapping';

import * as Audio from './audio_in';
import { InstrumentElement, NoteMappings } from './instruments/note_mapping';
import { dbg } from './debug/debug';

export const CHROMATIC = 'None (Chromatic)';
export const PERFECT = 'Equal (Perfect Oct)';

export const transpositionStrings = [
  'None',
  'C# (C->B)',
  'D (C->A#)',
  'D# (C->A)',
  'E (C->G#)',
  'F (C->G)',
  'F# (C->F#)',
  'G (C->F)',
  'G# (C->E)',
  'A (C->D#)',
  'A# (C->D)',
  'B (C->C#)',
];

export type StrobeState = {
  velocity: number;
  color: string;
  relativeAmplitude: number;
  centsError: number;
};

export const strobeStateDefault: StrobeState = {
  velocity: 0,
  color: 'green',
  relativeAmplitude: 1,
  centsError: 0,
};

export function getStrobeState(idx: number, strobe: StrobeEvt, maxAmplitude: number): StrobeState {
  const frameVelocity = (-strobe.velocity * Math.PI) / 2 / (idx + 1);
  const color = Math.abs(strobe.centsError) < 10 ? 'green' : 'orange';
  const s: StrobeState = {
    velocity: frameVelocity,
    color: color,
    relativeAmplitude: strobe.amp / maxAmplitude,
    centsError: strobe.centsError,
  };
  return s;
}

function getReferenceFreqWithTranspose(mReferenceFreq: number, mTransposeFact: number) {
  return mReferenceFreq * mTransposeFact;
}

export function getReferenceFreq(transposeSemitones: number, refFreq: number) {
  const transposeFact = Math.pow(2.0, Math.floor(transposeSemitones) / 12.0);
  //final double oldRefToFreq = mFreqToRef;
  const newRefFreq = getReferenceFreqWithTranspose(refFreq, transposeFact);
  //const mFreqToRef = 440.0 / newRefFreq;
  //  const mRefToFreq = 1.0 / mFreqToRef;
  return newRefFreq;
}

export interface GlobalState {
  isInit: boolean;
  audioStarted: boolean;
  minFreq: number; // TODO: Implement reinit when changed.
  maxFreq: number; // TODO: Implement reinit when changed.
  referenceFreq: number;
  transposition: number;
  noteEvt: NoteEvt;
  threshEvt: ThreshEvt;
  noteString: string;
  octaveString: string;
  freqHz: string;
  centsError: string;
  isInTune: boolean;
  textColor: string;
  isAnalyzing: boolean;
  strobe0State: StrobeState;
  strobe1State: StrobeState;
  strobe2State: StrobeState;
  allInstrumentsMap: Map<string, InstrumentElement>;
  allTemperamentsMap: Map<string, TuningElement>;
  currentTemperamentName: string;
  currentInstrumentName: string;
  currentSubInstrumentName: string;
  currentTuningName: string;
  currentTuning?: TuningElement;
}

export const defaultGlobalState: GlobalState = {
  isInit: false,
  audioStarted: false,
  minFreq: 41,
  maxFreq: 11050,
  referenceFreq: 440,
  transposition: 0,
  noteEvt: NoteEvtDefault,
  threshEvt: ThreshEvtDefault,
  noteString: '',
  octaveString: '',
  freqHz: '',
  centsError: '',
  isInTune: true,
  textColor: 'green',
  isAnalyzing: false,
  strobe0State: strobeStateDefault,
  strobe1State: strobeStateDefault,
  strobe2State: strobeStateDefault,
  allInstrumentsMap: new Map(),
  allTemperamentsMap: new Map(),
  currentTemperamentName: 'None',
  currentInstrumentName: 'Chromatic',
  currentSubInstrumentName: 'None',
  currentTuningName: 'None',
};

export interface ReduxActions {
  type: string;
}

export interface InitAction extends ReduxActions {}

export interface SetReferenceFreqAction extends ReduxActions {
  referenceFreq: number;
}

export interface SetTranspositionAction extends ReduxActions {
  transposition: number;
}

export interface SetNoteEvtAction extends ReduxActions {
  noteEvt: NoteEvt;
}

export interface SetThreshEvtAction extends ReduxActions {
  threshEvt: ThreshEvt;
}

export interface StartAudioAction extends ReduxActions {
  start: boolean;
}

export interface AddInstrumentAction extends ReduxActions {
  instrument: InstrumentElement;
}

export interface AddTemperamentAction extends ReduxActions {
  temperament: TuningElement;
}

export interface SetCurrentTemperamentAction extends ReduxActions {
  temperamentName: string;
}

export interface SetCurrentInstrumentAction extends ReduxActions {
  instrumentName: string;
  subInstrumentName: string;
  tuningName: string;
}

export interface SetTuningAction extends ReduxActions {
  tuning: null | TuningElement;
}

export const ActionNames = {
  InitAction: 'InitAction',
  SetReferenceFreqAction: 'SetReferenceFreqAction',
  SetTranspositionAction: 'SetTranspositionAction',
  SetNoteEvtAction: 'SetNoteEvtAction',
  SetThreshEvtAction: 'SetThreshEvtAction',
  StartAudioAction: 'StartAudioAction',
  AddInstrumentAction: 'AddInstrumentAction',
  AddTemperamentAction: 'AddTemperamentAction',
  SetCurrentTemperamentAction: 'SetCurrentTemperamentAction',
  SetCurrentInstrumentAction: 'SetCurrentInstrumentAction',
  SetTuningAction: 'SetTuningAction',
};

export const InfoNames = {
  TemperamentsUpdateCompleted: 'TemperamentsUpdateCompleted',
  InstrumentsUpdateCompleted: 'InstrumentsUpdateCompleted',
};

export const Dispatch = {
  init: function (): InitAction {
    const rval: InitAction = {
      type: ActionNames.InitAction,
    };
    return rval;
  },

  info: function (infoString: string): ReduxActions {
    const rval: ReduxActions = {
      type: infoString,
    };
    return rval;
  },

  setRefFreq: function (referenceFreq: number): SetReferenceFreqAction {
    const rval: SetReferenceFreqAction = {
      type: ActionNames.SetReferenceFreqAction,
      referenceFreq: referenceFreq,
    };
    return rval;
  },

  setTransposition: function (transposition: number): SetTranspositionAction {
    const rval: SetTranspositionAction = {
      type: ActionNames.SetTranspositionAction,
      transposition: Math.floor(transposition) % 12,
    };
    return rval;
  },

  setNoteEvt: function (noteEvt: NoteEvt): SetNoteEvtAction {
    const rval: SetNoteEvtAction = {
      type: ActionNames.SetNoteEvtAction,
      noteEvt: noteEvt,
    };
    return rval;
  },

  setThreshEvt: function (threshEvt: ThreshEvt): SetThreshEvtAction {
    const rval: SetThreshEvtAction = {
      type: ActionNames.SetThreshEvtAction,
      threshEvt: threshEvt,
    };
    return rval;
  },

  startAudio: function (start: true): StartAudioAction {
    const rval: StartAudioAction = {
      type: ActionNames.StartAudioAction,
      start: start,
    };
    return rval;
  },

  addInstrument: function (instrument: InstrumentElement): AddInstrumentAction {
    const rval: AddInstrumentAction = {
      type: ActionNames.AddInstrumentAction,
      instrument: instrument,
    };
    return rval;
  },

  addTemperament: function (temperament: TuningElement): AddTemperamentAction {
    const rval: AddTemperamentAction = {
      type: ActionNames.AddTemperamentAction,
      temperament: temperament,
    };
    return rval;
  },

  setCurrentTemperamentByName: function (temperamentName: string): SetCurrentTemperamentAction {
    const rval: SetCurrentTemperamentAction = {
      type: ActionNames.SetCurrentTemperamentAction,
      temperamentName: temperamentName,
    };
    return rval;
  },

  setCurrentInstrumentByName: function (
    instrumentName: string,
    subInstrumentName: string,
    tuningName: string,
  ): SetCurrentInstrumentAction {
    const rval: SetCurrentInstrumentAction = {
      type: ActionNames.SetCurrentInstrumentAction,
      instrumentName: instrumentName,
      subInstrumentName: subInstrumentName,
      tuningName: tuningName,
    };
    return rval;
  },

  setTuning: function (tuning: null | TuningElement): SetTuningAction {
    const rval: SetTuningAction = {
      type: ActionNames.SetTuningAction,
      tuning: tuning,
    };
    return rval;
  },
};

const initialSystemState: GlobalState = { ...defaultGlobalState };

function systemReducer(state: GlobalState = initialSystemState, action: ReduxActions): GlobalState {
  const actionAny: any = action;
  let newState: undefined | GlobalState = undefined;

  switch (action.type) {
    case ActionNames.InitAction: {
      if (!state.isInit) {
        StroboProCpp.inst()._tunerPromise?.then(() => {
          StroboProCpp.inst().WrapperSetCallback((_noteEvt?: NoteEvt, _threshEvt?: ThreshEvt) => {
            onThreshEvt(_noteEvt, _threshEvt);
          });
        });
        NoteMappings.inst().hi();
        newState = { ...state, isInit: true };
      }
      break;
    }

    case ActionNames.StartAudioAction: {
      const a: StartAudioAction = actionAny;
      if (a.start) {
        if (a.start !== state.audioStarted) {
          StroboProCpp.inst()._tunerPromise?.then(() => {
            StroboProCpp.inst().WrapperSetCallback((_noteEvt?: NoteEvt, _threshEvt?: ThreshEvt) => {
              onThreshEvt(_noteEvt, _threshEvt);
            });

            const audio = Audio.TunerAudio.inst();
            audio.hi();
          });
          newState = { ...state, audioStarted: true };
        }
      }
      break;
    }

    case ActionNames.SetReferenceFreqAction: {
      const a: SetReferenceFreqAction = actionAny;

      if (a.referenceFreq) {
        if (state.referenceFreq > 0 && a.referenceFreq !== state.referenceFreq) {
          const refFreq = getReferenceFreq(state.transposition, a.referenceFreq);
          StroboProCpp.inst().WrapperChangeReferenceFreq(refFreq);
          newState = {
            ...state,
            referenceFreq: a.referenceFreq,
          };

          setItem(TingsToStore.ReferenceFrequency, a.referenceFreq.toFixed(3)).catch();
        }
      }
      break;
    }

    case ActionNames.SetTranspositionAction: {
      const a: SetTranspositionAction = actionAny;

      if (a.transposition >= 0) {
        const refFreq = getReferenceFreq(a.transposition, state.referenceFreq);
        StroboProCpp.inst().WrapperChangeReferenceFreq(refFreq);

        newState = {
          ...state,
          transposition: a.transposition,
        };
        const t = Math.floor(a.transposition) % 12;
        setItem(TingsToStore.Transposition, t.toString()).catch();
      }
      break;
    }

    case ActionNames.SetNoteEvtAction: {
      const a: SetNoteEvtAction = actionAny;
      if (a.noteEvt) {
        if (a.noteEvt !== state.noteEvt) {
          const noteEvt = a.noteEvt;
          const absErr = Math.abs(noteEvt.centsError);
          const isInTune = absErr < 10;
          //dbg.log('Got noteEvt:' + noteEvt.note + noteEvt.octave.toString());

          newState = {
            ...state,
            noteString: noteEvt.note,
            octaveString: noteEvt.octave.toFixed(0),
            centsError: noteEvt.centsError.toFixed(0),
            freqHz: '' + noteEvt.frequency.toFixed(2) + 'Hz',
            textColor: isInTune ? 'green' : 'orange',
            isInTune: isInTune,
            noteEvt: a.noteEvt,
          };
        }
      }
      break;
    }

    case ActionNames.SetThreshEvtAction: {
      const a: SetThreshEvtAction = actionAny;
      if (a.threshEvt) {
        if (a.threshEvt !== state.threshEvt) {
          const threshEvt = a.threshEvt;
          newState = { ...state };
          if (threshEvt.analysisActive) {
            newState.isAnalyzing = true;
            let maxAmplitude = 0;
            for (let i = 0; i < threshEvt.strobesActive; i++) {
              maxAmplitude = Math.max(maxAmplitude, threshEvt.strobes[i].amp);
            }
            maxAmplitude = maxAmplitude > 0 ? maxAmplitude : 1;
            if (threshEvt.strobesActive) {
              newState.strobe0State = getStrobeState(0, threshEvt.strobes[0], maxAmplitude);
              newState.strobe1State = getStrobeState(1, threshEvt.strobes[1], maxAmplitude);
              newState.strobe2State = getStrobeState(2, threshEvt.strobes[2], maxAmplitude);
            }
          } else {
            newState.textColor = 'lightgrey';
            newState.centsError = '0';
            newState.freqHz = 'listening...';
            newState.isAnalyzing = false;
            newState.strobe0State = { ...strobeStateDefault, color: newState.strobe0State.color };
            newState.strobe1State = { ...strobeStateDefault, color: newState.strobe1State.color };
            newState.strobe2State = { ...strobeStateDefault, color: newState.strobe2State.color };
          }
        }
      }
      break;
    }

    case ActionNames.AddTemperamentAction: {
      const a: AddTemperamentAction = actionAny;
      newState = { ...state };
      newState.allTemperamentsMap.set(a.temperament.name, a.temperament);

      break;
    }

    case ActionNames.AddInstrumentAction: {
      const a: AddInstrumentAction = actionAny;
      newState = { ...state };
      newState.allInstrumentsMap.set(a.instrument.name, a.instrument);

      break;
    }

    case ActionNames.SetCurrentTemperamentAction: {
      const a: SetCurrentTemperamentAction = actionAny;
      newState = state;
      newState.currentTemperamentName = a.temperamentName.length > 0 ? a.temperamentName : PERFECT;

      // Note, setting current instrument will result in SetTemperament, so no need to save
      // temperament here.

      break;
    }

    case ActionNames.SetCurrentInstrumentAction: {
      const a: SetCurrentInstrumentAction = actionAny;
      newState = state;
      newState.currentInstrumentName = a.instrumentName.length > 0 ? a.instrumentName : CHROMATIC;
      newState.currentSubInstrumentName =
        a.subInstrumentName.length > 0 ? a.subInstrumentName : 'None';
      newState.currentTuningName = a.tuningName.length > 0 ? a.tuningName : 'None';

      // Note, setting current instrument will result in SetTuning, so no need to save
      // tuning here.
      break;
    }

    case ActionNames.SetTuningAction: {
      const a: SetTuningAction = actionAny;
      newState = state;
      dbg.log('Got tuning ', a.tuning?.name);
      newState.currentTuningName = CHROMATIC;
      newState.currentSubInstrumentName = 'None';
      newState.currentTuningName = 'None';
      if (a.tuning) {
        const parent = a.tuning.parent;
        const grandparent = parent?.parent;
        if (parent && grandparent) {
          // From tuning, navigate upwards to find parent and grandparent.
          newState.currentTuningName = a.tuning.name.length > 0 ? a.tuning.name : CHROMATIC;
          newState.currentSubInstrumentName = parent.name;
          newState.currentInstrumentName =
            grandparent.name.length > 0 ? grandparent.name : CHROMATIC;
        }
      } else {
        newState.currentInstrumentName = CHROMATIC;
        newState.currentSubInstrumentName = 'None';
        newState.currentTuningName = 'None';
      }
      newState.currentTuning = a.tuning ? a.tuning : undefined;
      break;
    }

    default: {
      dbg.log('Unknown action:' + actionAny.type);
      break;
    }
  }

  newState = newState ? newState : state;
  //dbg.log('newState.allInstrumentsMap', newState.allInstrumentsMap);
  return newState;
}

// ----------------------------------------------------------------------------
// End blahblah reducers
// ----------------------------------------------------------------------------

const rootReducer = combineReducers({
  system: systemReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

// store.js
function configureStore(initialState = {}) {
  //dbg.log('configureStore');
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(NoteMappingMiddleWare, sagaMiddleware),
  );
  return store;
}
const _store = configureStore();

// Run any listeners that will trigger from actions....
// TODO move to a smarter part.
NoteMappingSideEffects.forEach((value) => {
  sagaMiddleware.run(value);
});

let storeInst: Store | null = null;
export class Store {
  store = _store;

  static inst(): Store {
    if (storeInst === null) {
      storeInst = new Store();

      setTimeout(() => {
        StroboProCpp.inst()._tunerPromise?.then(() => {
          try {
            _store.dispatch(Dispatch.init());
          } catch (e: any) {
            dbg.log('Got error when dispatching ', JSON.stringify(e, null, 2));
          }
        });
      }, 1000);

      setTimeout(() => {
        getItem(TingsToStore.ReferenceFrequency).then((s: null | string) => {
          getItem(TingsToStore.Transposition).then((t: null | string) => {
            let refFreq = 440;
            let transposition = 0;
            if (s) {
              refFreq = parseFloat(s);
            }
            if (t) {
              transposition = Math.round(parseFloat(t));
            }
            StroboProCpp.inst()._tunerPromise?.then(() => {
              try {
                _store.dispatch(Dispatch.setRefFreq(refFreq));
                _store.dispatch(Dispatch.setTransposition(transposition));
              } catch (e: any) {
                dbg.log('Got error when dispatching ', JSON.stringify(e, null, 2));
              }
            });
          });
        });
      }, 2000);
    }
    return storeInst;
  }
}

export const store = Store.inst().store;

export type AppDispatch = typeof store.dispatch;

function onThreshEvt(_noteEvt?: NoteEvt, _threshEvt?: ThreshEvt) {
  if (_noteEvt) {
    store.dispatch(Dispatch.setNoteEvt(_noteEvt));
  }
  if (_threshEvt) {
    store.dispatch(Dispatch.setThreshEvt(_threshEvt));
  }
}
