// tslint:disable-next-line
import AsyncStorage from '@react-native-async-storage/async-storage';

export const TingsToStore = {
  ReferenceFrequency: 'ReferenceFrequency',
  Transposition: 'Transposition',
  Instrument: 'Instrument',
  SubInstrument: 'SubInstrument',
  Tuning: 'Tuning',
  Temperament: 'Temperament',
};

export function setItem(key: string, item: string): Promise<void> {
  return AsyncStorage.setItem(key, item);
}

export function getItem(key: string): Promise<string | null> {
  return AsyncStorage.getItem(key);
}
