import { responsiveFontSizes } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

/*
252525
6930C3
64DFDF
80FFDB
*/

export const colorBackground = '#252525';
export const colorDarkest = '#6930C3';
export const colorLighter = '#64DFDF';
export const colorLightest = '#80FFDB';

let theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

export const MyTheme = responsiveFontSizes(theme);
//dbg.log('theme', JSON.stringify(MyTheme, null, 2));
