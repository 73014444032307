import * as THREE from 'three';
import React, { useRef, useEffect, useState } from 'react';
import { Canvas, CanvasContext, useFrame, useThree } from 'react-three-fiber';
/* important!! Update to released ract-spring when it is available.
    https://github.com/pmndrs/react-spring/issues/1078
*/
import { useSpring as useSpringGl, animated as anim } from 'react-spring/three';
// https://material-ui.com/components/material-icons/
import { createStyles, fade, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { Text } from 'drei';
import { withRouter } from 'react-router-dom';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { PersistentDrawerLeft } from './persistent_drawer_left';
import './App.css';
import { dbg } from './debug/debug';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';

export function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  );
}

export function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  );
}

export function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className='close' fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z' />
    </SvgIcon>
  );
}

export function Dash(props: any) {
  return <KeyboardArrowRight style={{ width: 14, height: 14 }}></KeyboardArrowRight>;
}

export function Down(props: any) {
  return <KeyboardArrowDown style={{ width: 14, height: 14 }}></KeyboardArrowDown>;
}

export function None(props: any) {
  return null;
}

// Create an AnimatedText component from drei's text component.
const AnimatedTextGl = anim(Text);

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  }),
)((props: TreeItemProps) => <TreeItem {...props} />);

//-----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),

      width: '100%',
    },
    tuningRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      textTransform: 'none',
    },
    title: {
      margin: theme.spacing(2),
      flexDirection: 'column',
    },
  });
});

//-----------------------------------------------------------------------------
type TorusProps = {
  active?: boolean;
  initRotation?: number;
  arc?: number;
  ringDiameter: number;
  diameter: number;
  amplitude: number;
  cents?: number;
  velocity?: number;
  color?: string;
  props?: any;
};

const YPOS_3D_STUFF = 20;
//const YPOS_3D_STUFF_UNDER = YPOS_3D_STUFF - 2;
const ROTATION_AROUND_X = 0.9 * 0.33333333;
//-----------------------------------------------------------------------------
// Inner ring of the Torus. As the torus doesn't specify start and end angle, two of these make up one of the rings.
//-----------------------------------------------------------------------------
function TorusInner(props: TorusProps) {
  const meshRef = useRef();
  const initRotation = props.initRotation !== undefined ? props.initRotation : 0;
  const velocity = props.velocity !== undefined ? props.velocity : 0;
  const color = props.color ? props.color : 'white';
  const radius = props.diameter / 2;
  const torus = props.ringDiameter / 2;
  const arc = props.arc ? props.arc : Math.PI / 4;
  const active = props.active ? props.active : false;
  const opacityProps = useSpringGl({
    opacity: active ? 1 * props.amplitude : 0.01 * props.amplitude,
  });

  useFrame((_state: CanvasContext, _delta: number) => {
    // tslint:disable-next-line
    const _mesh: any = meshRef;

    if (_mesh && _mesh.current && _mesh.current.rotation) {
      let rotation = _mesh.current.rotation.z;
      rotation += velocity * _delta;
      if (rotation < 0) {
        rotation += 2 * Math.PI;
      } else if (rotation > 2 * Math.PI) {
        rotation -= 2 * Math.PI;
      }
      _mesh.current.rotation.z = rotation;
    }
  });

  return (
    <anim.mesh
      ref={meshRef}
      position={[0, YPOS_3D_STUFF, 0]}
      rotation={[-Math.PI * ROTATION_AROUND_X, 0, initRotation]}
    >
      <torusBufferGeometry attach='geometry' args={[radius, torus, 128, 12, arc]} />
      <anim.meshPhysicalMaterial
        color={color}
        transparent={true}
        reflectivity={0.5}
        flatShading={false}
        roughness={1}
        clearcoat={0.8}
        opacity={opacityProps.opacity}
      />
    </anim.mesh>
  );
}

// This example shows using any generic
function TorusOuter(_props: TorusProps) {
  const props2 = { ..._props, initRotation: Math.PI };

  return (
    <>
      <TorusInner {..._props}></TorusInner>
      <TorusInner {...props2}></TorusInner>
    </>
  );
}

//-----------------------------------------------------------------------------
type NoteProps = {
  text?: string;
  small?: boolean;
  up?: boolean;
  isAnalyzing: boolean;
  textColor: string;
};

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
function NoteTextGl(nprops: NoteProps) {
  const { viewport } = useThree();
  const minDim = Math.min(viewport.width, viewport.height);
  const fontSize = nprops.small ? minDim / 10 : minDim / 5;
  const anchorY = !nprops.small ? 'middle' : nprops.up ? 'bottom' : 'top';

  const colorProps = useSpringGl({
    interpColor: nprops.isAnalyzing ? nprops.textColor : backgroundColor,
  });
  const opacityProps = useSpringGl({ opacity: nprops.isAnalyzing ? 1 : 0 });

  return (
    <anim.mesh>
      <AnimatedTextGl
        color={colorProps.interpColor}
        position={[0, YPOS_3D_STUFF, 0]}
        textAlign='center'
        fontSize={fontSize}
        anchorX={'center'}
        anchorY={anchorY}
        attach='geometry'
      >
        {nprops.text}
      </AnimatedTextGl>
      <anim.meshPhysicalMaterial
        color={colorProps.interpColor}
        reflectivity={0.5}
        flatShading={false}
        roughness={1}
        clearcoat={0.8}
        transparent={true}
        opacity={opacityProps.opacity}
      />
    </anim.mesh>
  );
}
//-----------------------------------------------------------------------------
interface CanvasDims {
  width: number;
  height: number;
}

//-----------------------------------------------------------------------------
function newCanvasDims(w: number, h: number): CanvasDims {
  return { width: 0.95 * w, height: 0.85 * h };
}

const backgroundColor: string = '#252525';

//-----------------------------------------------------------------------------
interface TuningViewProps {
  canvasDims: CanvasDims;
}

function MyGlTuningView(p: TuningViewProps) {
  const noteString = 'Aa';

  const isAnalyzing = true;

  const textColor = 'white';

  const biggify = 1.8;
  const velocity = 0.2;

  return (
    <Canvas
      shadowMap
      camera={{ position: [0, 0, 80] }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -2,
        height: p.canvasDims.height,
        width: p.canvasDims.width,
        opacity: 0.03,
      }}
      onCreated={({ gl }) => {
        gl.toneMapping = THREE.ReinhardToneMapping;
        gl.setClearColor(new THREE.Color('#252525'));
      }}
      gl={{ antialias: true }}
    >
      <ambientLight intensity={0.5} />
      <spotLight position={[200, 200, 200]} angle={0.15} penumbra={1} />

      <TorusOuter
        ringDiameter={2}
        diameter={40 * biggify}
        velocity={velocity}
        color={textColor}
        amplitude={1.0}
        active={isAnalyzing}
      ></TorusOuter>
      <TorusOuter
        ringDiameter={2}
        diameter={70 * biggify}
        velocity={velocity}
        color={textColor}
        amplitude={1.0}
        active={isAnalyzing}
      ></TorusOuter>
      <TorusOuter
        ringDiameter={2}
        diameter={100 * biggify}
        velocity={velocity}
        color={textColor}
        amplitude={1.0}
        active={isAnalyzing}
      ></TorusOuter>
      <NoteTextGl text={noteString} isAnalyzing={isAnalyzing} textColor={textColor}></NoteTextGl>
    </Canvas>
  );
}

//-----------------------------------------------------------------------------
function App() {
  const classes = useStyles();

  const [canvasDims, setCanvasDims] = useState(
    newCanvasDims(window.innerWidth, window.innerHeight),
  );
  dbg.log(canvasDims);

  // Called when the window changes size.
  const updateDimensions = (ev: UIEvent) => {
    const { innerWidth: w, innerHeight: h } = window;
    setCanvasDims(newCanvasDims(w, h));
    //dbg.log('new dimensions:', w, h);
  };

  const listenToScroll = (ev: Event): any => {
    dbg.log('Scrolled to:', ev);
    return;
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const ListItem = (itemName: string, details?: string) => {
    const d: string = !!details ? details : '';
    if (!!details) {
      return (
        <StyledTreeItem key={itemName} nodeId={itemName} label={itemName}>
          <Typography>{d}</Typography>
        </StyledTreeItem>
      );
    } else {
      return <StyledTreeItem key={itemName} nodeId={itemName} label={itemName}></StyledTreeItem>;
    }
  };

  return (
    <>
      <MyGlTuningView canvasDims={canvasDims} />

      <PersistentDrawerLeft placeContentUnderAppBar={true}>
        <div className={classes.title}>
          <Typography variant='h4'>Core Competencies</Typography>
          <TreeView
            className={classes.root}
            defaultExpanded={['1']}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<None />}
          >
            {ListItem(
              'Embedded Firmware and Hardware',
              'Considerable experience with implementing embedded hardware and bare-metal firmware.',
            )}
            {ListItem(
              'Bluetooth & BLE',
              'Implementing, profiling Bluetooth & BLE middleware, apps, audio profiles, and ports.',
            )}
            {ListItem(
              'Security and Cryptography',
              'Best Practices regarding security and cryptography, especially in embedded and portable scenarios.',
            )}
            {ListItem('Audio DSP', 'We enjoy making things sound rad.')}
            {ListItem(
              'Web Apps',
              'Although our specialty is under the hood, we have recently started delivering web apps. (this is new for us).',
            )}
            {ListItem(
              'Mobile Apps',
              'iOS and Android apps, with special focus on implementing cross-platform layers in C++/Javascript to minimize platform-specific code.',
            )}
            {ListItem(
              'DevOps',
              'Agile DevOps - CI/CD of many flavours, but Github Actions is our favourite!',
            )}
            {ListItem(
              'Cross-Platform Hardware and OS Ports',
              'Extensive experience with OS Abstraction Layers, Hardware Abstraction Layers. AL is 👍.',
            )}
          </TreeView>
        </div>
      </PersistentDrawerLeft>
    </>
  );
}

//-----------------------------------------------------------------------------
export default withRouter(App);
