import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { GraphicEq, ChevronLeft, ChevronRight, Menu } from '@material-ui/icons';
import { DrawerItems } from './persistent_drawer_left';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    titleBar: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),

      backgroundColor: 'rgba(1,1,1,0.3)',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerHeaderOverlap: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      justifyContent: 'space-around',
    },
    drawerHeaderBelowAppBar: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      ...theme.mixins.toolbar,
      justifyContent: 'space-around',
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export interface PersistentDrawerLeftProps {
  onPressItem?: (itemIdx: number) => void;
  placeContentUnderAppBar?: boolean;
  children?: JSX.Element;
}

type MyListItemProps = {
  primary: string;
  secondary: string;
  onClick: () => void;
  children: JSX.Element | Array<JSX.Element>;
};

function MyListItem(props: MyListItemProps) {
  return (
    <ListItem button key={props.primary} onClick={props.onClick}>
      <ListItemIcon>{props.children}</ListItemIcon>
      <ListItemText primary={props.primary} secondary={props.secondary} />
    </ListItem>
  );
}

export function PersistentDrawerLeftChild(props: PersistentDrawerLeftProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
          </IconButton>
          <div className={classes.titleBar}>
            <Typography variant='h6' noWrap>
              Applicaudia AB
            </Typography>
            <img width={64} height={64} alt='Logo' src='applicaudia_logo.png'></img>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <MyListItem
            primary={'Contact Email'}
            secondary={'support@applicaudia.se'}
            onClick={() => {
              setOpen(false);
              if (!!props.onPressItem) {
                props.onPressItem(DrawerItems.CONTACT);
              }
            }}
          >
            <ListItemIcon>
              <GraphicEq />{' '}
            </ListItemIcon>
          </MyListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {!props.placeContentUnderAppBar ? (
          <div className={classes.drawerHeaderOverlap} />
        ) : (
          <div className={classes.drawerHeaderBelowAppBar} />
        )}
        {props.children}
      </main>
    </div>
  );
}
